@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('../fonts/BasisGrotesquePro-Regular.eot');
  src: local('Basis Grotesque Pro'), local('BasisGrotesquePro-Regular'),
  url('../fonts/BasisGrotesquePro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BasisGrotesquePro-Regular.woff2') format('woff2'),
  url('../fonts/BasisGrotesquePro-Regular.woff') format('woff'),
  url('../fonts/BasisGrotesquePro-Regular.ttf') format('truetype'),
  url('../fonts/BasisGrotesquePro-Regular.svg#BasisGrotesquePro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('../fonts/BasisGrotesquePro-Light.eot');
  src: local('Basis Grotesque Pro Light'), local('BasisGrotesquePro-Light'),
  url('../fonts/BasisGrotesquePro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BasisGrotesquePro-Light.woff2') format('woff2'),
  url('../fonts/BasisGrotesquePro-Light.woff') format('woff'),
  url('../fonts/BasisGrotesquePro-Light.ttf') format('truetype'),
  url('../fonts/BasisGrotesquePro-Light.svg#BasisGrotesquePro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Mono';
  src: url('../fonts/NotoMono.eot');
  src: local('Noto Mono'), local('NotoMono'),
  url('../fonts/NotoMono.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NotoMono.woff2') format('woff2'),
  url('../fonts/NotoMono.woff') format('woff'),
  url('../fonts/NotoMono.ttf') format('truetype'),
  url('../fonts/NotoMono.svg#NotoMono') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

