body {
  font-family: NotoMono, sans-serif;
  background: #151515;
  font-size: get-vw(16px);
  position: relative;

  @include respond-above(lg) {
    font-size: get-vw-lg(16px);
  }

  @include respond-above(sm) {
    font-size: get-vw-sm(16px);
  }
}

*::selection {
  background-color: #ff274f;
  color: #fff;
}

.body-lines {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;

  @include respond-above(sm) {
    display: none;
  }

  .line {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.2;
    background-color: #ffffff;
    left: 50%;
    width: 1px;
  }
}

.footer {
  background: #151515;
  height: 22vh;
  display: flex;
  justify-content: space-between;
  padding: get-vw(30px);
  align-items: flex-end;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  @include respond-above(lg) {
    padding: get-vw-lg(30px) get-vw-lg(15px) get-vw-lg(20px) get-vw-lg(20px);
  }

  @include respond-above(sm) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: get-vw-sm(155px);
    padding: get-vw-sm(30px) get-vw-sm(15px) get-vw-sm(20px) get-vw-sm(20px);
  }

  .address {
    padding-bottom: get-vw(7px);

    @include respond-above(lg) {
      padding-bottom: get-vw-lg(7px);
    }

    @include respond-above(sm) {
      padding-bottom: 0;
      order: 2;
    }

    p {
      opacity: 0.7;
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(12px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      text-transform: uppercase;

      @include respond-above(lg) {
        font-size: get-vw-lg(12px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(12px);
      }
    }
  }

  .email {
    @include respond-above(sm) {
      margin-bottom: get-vw-sm(25px);
      order: 1;
    }

    a {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(64px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.16;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;
      text-transform: uppercase;
      text-decoration: none;
      transition: .2s ease-in-out color;
      white-space: nowrap;

      @include respond-above(lg) {
        font-size: get-vw-lg(33px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(33px);
      }

      &:hover {
        color: #FF274F;
      }
    }
  }
}

.cap-wrap {
  padding: get-vw(6px);
  box-sizing: border-box;
  height: 78vh;
  position: relative;
  z-index: 1;

  @include respond-above(lg) {
    padding: get-vw-lg(5px);
  }

  @include respond-above(sm) {
    height: get-vw-sm(627px);
    padding: get-vw-sm(5px);
  }

  .cap {
    border-radius: get-vw(30px);
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100%;
    position: relative;

    @include respond-above(lg) {
      border-radius: get-vw-lg(20px);
    }

    @include respond-above(sm) {
      border-radius: get-vw-sm(20px);
    }

    .lines {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 0;

      .line {
        opacity: 0.3;
        background-color: #000000;
        position: absolute;

        &:first-child {
          left: 50%;
          top: 0;
          bottom: 0;
          width: 1px;
        }

        &:last-child {
          left: 0;
          right: 0;
          height: 1px;
          top: 31%;

          @include respond-above(sm) {
            top: get-vw-sm(142px);
          }
        }
      }
    }

    .logo {
      position: absolute;
      z-index: 1;
      width: get-vw(310px);
      left: 34.9%;
      top: 9%;

      @include respond-above(lg) {
        width: get-vw-lg(180px);
        left: 32.8%;
      }

      @include respond-above(sm) {
        width: get-vw-sm(180px);
        top: get-vw-sm(51px);
        left: 15.8%;
      }

      img {
        width: 100%;
        display: block;
      }
    }

    .ball-wrap {
      position: absolute;
      right: get-vw(114px);
      top: 12%;
      z-index: 1;
      border-radius: 50%;
      box-shadow: -30px 62px 60px 0 rgba(0, 0, 0, 0.2);
      display: inline-block;
      width: get-vw(260px);
      overflow: hidden;

      @include respond-above(lg) {
        right: get-vw-lg(24px);
        width: get-vw-lg(178px);
        top: 15%;

        @media (orientation: landscape) {
          width: get-vw-lg(120px);
          top: 12%;
        }
      }

      @include respond-above(sm) {
        right: get-vw-sm(24px);
        width: get-vw-sm(178px);
        top: get-vw-sm(122px);
      }

      img {
        display: block;
        width: 100%;
      }

      video {
        display: block;
        width: 100%;
        outline: none;
        -webkit-appearance: none;
      }
    }

    .text {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 50%;
      padding: 0 get-vw(138px) get-vw(60px) get-vw(35px);

      @include respond-above(lg) {
        padding: 0 get-vw-lg(26px) get-vw-lg(50px) get-vw-lg(26px);
      }

      @include respond-above(sm) {
        left: 0;
        padding: 0 get-vw-sm(26px) get-vw-sm(50px) get-vw-sm(26px);
      }

      .comming-soon {
        font-family: NotoMono, sans-serif;
        font-size: get-vw(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #ffffff;
        padding: get-vw(2px) get-vw(24px);
        border-radius: get-vw(13px);
        background-color: #000000;
        display: inline-block;
        box-sizing: border-box;
        margin-bottom: get-vw(29px);

        @include respond-above(lg) {
          font-size: get-vw-lg(14px);
          padding: get-vw-lg(2px) get-vw-lg(20px);
          border-radius: get-vw-lg(13px);
          margin-bottom: get-vw-lg(29px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(14px);
          padding: get-vw-sm(2px) get-vw-sm(20px);
          border-radius: get-vw-sm(13px);
          margin-bottom: get-vw-sm(29px);
        }
      }

      p {
        margin: 0;
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(30px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #000000;

        a {
          color: #000000;
          white-space: nowrap;
          transition: .2s ease-in-out color;

          &:hover {
            color: #FF274F;
          }
        }

        @include respond-above(lg) {
          font-size: get-vw-lg(26px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(26px);
        }
      }
    }
  }
}

.main-header-wrap {
  padding-top: get-vw(6px);
  overflow: hidden;
  border-radius: 0 0 get-vw(30px) get-vw(30px);
  margin: 0 get-vw(6px) get-vw(17px) get-vw(6px);

  @include respond-above(lg) {
    padding-top: get-vw-lg(5px);
    border-radius: 0 0 get-vw-lg(20px) get-vw-lg(20px);
    margin: 0 get-vw-lg(5px) get-vw-lg(14px) get-vw-lg(5px);
  }

  @include respond-above(sm) {
    padding-top: get-vw-sm(5px);
    border-radius: 0 0 get-vw-sm(20px) get-vw-sm(20px);
    margin: 0 get-vw-sm(5px) get-vw-sm(20px) get-vw-sm(5px);
  }
}

.main-header {
  background-color: #f0153d;
  position: relative;
  border-radius: get-vw(30px) get-vw(30px) 0 0;
  height: 98vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 98);

  @include respond-above(lg) {
    border-radius: get-vw-lg(20px) get-vw-lg(20px) 0 0;
  }

  @include respond-above(sm) {
    border-radius: get-vw-sm(20px) get-vw-sm(20px) 0 0;

    @media (orientation: landscape) {
      min-height: get-vw-sm(500px);
    }
  }

  .lines {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;

    .line {
      background-color: #ffffff;
      position: absolute;

      &:first-child {
        width: 1px;
        left: 50%;
        top: 0;
        bottom: 0;
      }

      &:nth-child(2) {
        top: get-vw(250px);
        left: 0;
        right: 0;
        height: 1px;

        @include respond-above(lg) {
          top: get-vw-lg(220px);
        }

        @include respond-above(sm) {
          top: get-vw-sm(172px);
        }
      }
    }

    .vertical-dop {
      top: 45%;
      width: get-vw(1px);
      background-color: #ffffff;
      position: absolute;
      height: get-vw(30px);

      @include respond-above(lg) {
        display: none;
      }

      &:nth-child(3) {
        left: 16.4%;
      }

      &:nth-child(4) {
        right: 16.4%;
      }
    }
  }

  .bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    opacity: 0.85;
    mix-blend-mode: multiply;

    @include respond-above(sm) {
      display: block;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;

      @include respond-above(sm) {
        height: 100%;
        width: auto;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .logo {
    width: get-vw(310px);
    position: absolute;
    z-index: 2;
    left: 34.95%;
    top: get-vw(95px);

    @include respond-above(lg) {
      width: get-vw-lg(180px);
      top: get-vw-lg(70px);
      left: 32.85%;
    }

    @include respond-above(sm) {
      width: get-vw-sm(180px);
      top: get-vw-sm(70px);
      left: 15.9%;
    }

    img {
      width: 100%;
    }
  }

  .text {
    position: absolute;
    bottom: 20%;
    left: 52.5%;
    right: 9.7%;
    z-index: 2;

    @include respond-above(lg) {
      right: get-vw-lg(26px);
      bottom: get-vw-lg(67px);
      left: 53%;
    }

    @include respond-above(sm) {
      right: get-vw-sm(26px);
      bottom: get-vw-sm(67px);
      left: get-vw-sm(26px);
    }

    h1 {
      margin-top: get-vw(29px);
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(30px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #ffffff;

      @include respond-above(lg) {
        margin-top: get-vw-lg(25px);
        font-size: get-vw-lg(26px);
      }

      @include respond-above(sm) {
        margin-top: get-vw-sm(25px);
        font-size: get-vw-sm(26px);
      }
    }
  }
}

.tag-label {
  font-family: NotoMono, sans-serif;
  font-size: get-vw(14px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
  border-radius: get-vw(13px);
  background-color: #ffffff;
  display: inline-block;
  box-sizing: border-box;
  padding: get-vw(2px) get-vw(24px);

  @include respond-above(lg) {
    font-size: get-vw-lg(14px);
    border-radius: get-vw-lg(13px);
    padding: get-vw-lg(2px) get-vw-lg(20px);
  }

  @include respond-above(sm) {
    font-size: get-vw-sm(14px);
    border-radius: get-vw-sm(13px);
    padding: get-vw-sm(2px) get-vw-sm(20px);
  }

  &.black {
    color: #ffffff;
    background-color: #000000;
  }
}

.nav-menu-trigger {
  @include respond-above(sm) {
    position: fixed;
    z-index: 100;
    top: get-vw-sm(20px);
    right: get-vw-sm(20px);

    span {
      width: get-vw-sm(26px);
      height: 2px;
      margin-bottom: get-vw-sm(7px);
      background: #fff;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@keyframes nav-opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

nav.main-navigation {
  position: fixed;
  right: get-vw(37px);
  top: get-vw(34px);
  z-index: 100;
  transition: .2s ease-in-out opacity;
  opacity: 1;

  @include respond-above(lg) {
    right: get-vw-lg(30px);
    top: get-vw-lg(30px);
  }

  @include respond-above(sm) {
    right: 0;
    z-index: 101;
    left: 0;
    top: 0;
    bottom: 0;
    background: #F0153D;
    display: flex;
    justify-content: space-between;
    transform: translateY(-120%);
    transition: none;

    &.active {
      transform: translateY(0);

      .menu, .lang {
        animation-duration: .3s;
        animation-delay: .3s;
        animation-name: nav-opacity;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }

  @media screen and (min-width: 767px) {
    &.footer-hide {
      opacity: 0;
      pointer-events: none;
      transition: .2s ease-in-out opacity;
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
      transition: .2s ease-in-out opacity;
    }
  }

  .lang {
    margin-bottom: get-vw(30px);
    text-align: right;

    @include respond-above(lg) {
      margin-bottom: get-vw-lg(20px);
    }

    @include respond-above(sm) {
      text-align: left;
      margin-bottom: 0;
      margin-left: get-vw-sm(15px);
      margin-top: get-vw-sm(20px);
      width: get-vw-sm(75px);
      opacity: 0;
    }

    li {
      margin-bottom: get-vw(26px);
      position: relative;

      @include respond-above(lg) {
        margin-bottom: get-vw-lg(20px);
      }

      @include respond-above(sm) {
        margin-bottom: get-vw-sm(40px);
      }

      &:after {
        content: '';
        width: 2px;
        height: 2px;
        border-radius: 100%;
        position: absolute;
        box-sizing: border-box;
        left: 0;
        bottom: get-vw(-12px);
        background: #fff;

        @include respond-above(lg) {
          bottom: get-vw-lg(-12px);
        }

        @include respond-above(sm) {
          width: 4px;
          height: 4px;
          bottom: auto;
          top: get-vw-sm(10px);
          left: auto;
          right: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;

        &:after {
          display: none;
        }
      }

      a {
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-decoration: none;
        position: relative;
        transition: .2s ease-in-out color;

        @include respond-above(lg) {
          font-size: get-vw-lg(14px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(20px);
        }

        &:after {
          content: '';
          left: get-vw(-10px);
          position: absolute;
          right: get-vw(-10px);
          background-color: #000000;
          top: get-vw(-3px);
          bottom: get-vw(-3px);
          z-index: -1;
          opacity: 0;
          transition: .2s ease-in-out opacity;

          @include respond-above(lg) {
            display: none;
          }
        }

        &:hover {
          color: #b1b1b1;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .menu {
    @include respond-above(sm) {
      margin-top: get-vw-sm(12px);
      margin-right: get-vw-sm(15px);
      text-align: right;
      opacity: 0;
    }

    li {
      margin-bottom: get-vw(10px);

      @include respond-above(lg) {
        margin-bottom: get-vw-lg(10px);
      }

      @include respond-above(sm) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(13px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-decoration: none;
        transition: .2s ease-in-out opacity, .2s ease-in-out color;
        position: relative;
        display: block;
        width: 100%;

        @include respond-above(lg) {
          font-size: get-vw-lg(13px);
        }

        @include respond-above(sm) {
          line-height: 2;
          font-size: get-vw-sm(20px);
        }

        &:after {
          content: '';
          left: get-vw(-10px);
          position: absolute;
          right: get-vw(-10px);
          background-color: #000000;
          top: get-vw(-3px);
          bottom: get-vw(-3px);
          z-index: -1;
          opacity: 0;
          transition: .2s ease-in-out opacity;

          @include respond-above(lg) {
            display: none;
          }
        }

        &.active {
          opacity: .5;
          pointer-events: none;
        }

        &:hover {
          color: #b1b1b1;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .nav-menu-close {
    @include respond-above(sm) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      svg {
        width: get-vw-sm(30px);
        height: get-vw-sm(30px);
      }
    }
  }
}

main {
  background: #2A2929;
  position: relative;
  z-index: 3;
  transition: .25s ease-in-out transform;
  border-radius: 0 0 get-vw(30px) get-vw(30px);
  transform-origin: center bottom;

  &.transparent {
    background: transparent;
  }

  @include respond-above(lg) {
    border-radius: 0 0 get-vw-lg(20px) get-vw-lg(20px);
  }

  @include respond-above(sm) {
    border-radius: 0 0 get-vw-sm(20px) get-vw-sm(20px);
  }

  &.round {
    border-radius: get-vw(30px);

    @include respond-above(lg) {
      border-radius: get-vw-lg(20px);
    }

    @include respond-above(sm) {
      border-radius: get-vw-sm(20px);
    }
  }

  &.scaled {
    transform: scale(0.95);

    &.top {
      &:after {
        display: none;
      }

      @include respond-above(lg) {
        transform: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0,0,0,0.1);
      z-index: 3;
      border-radius: get-vw(30px);

      @include respond-above(lg) {
        border-radius: get-vw-lg(20px);
      }

      @include respond-above(sm) {
        border-radius: get-vw-sm(20px);
      }
    }
  }
}

.main-tagline {
  padding: 0 get-vw(30px) get-vw(50px) get-vw(30px);
  margin-bottom: get-vw(50px);
  opacity: 0;
  transition: .2s ease-in-out opacity;
  pointer-events: none;

  @include respond-above(lg) {
    padding: 0 get-vw-lg(30px) get-vw-lg(50px) get-vw-lg(30px);
    margin-bottom: get-vw-lg(30px);
  }

  @include respond-above(sm) {
    padding: 0 get-vw-sm(30px) get-vw-sm(15px) get-vw-sm(30px);
    margin-bottom: get-vw-sm(15px);
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
  }

  .main-tagline-items {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .main-tagline-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &:nth-child(1) {
        width: 12%;
        margin-right: 3%;

        @include respond-above(lg) {
          width: 17%;
        }

        @include respond-above(sm) {
          width: 50%;
          margin: 0;
        }
      }

      &:nth-child(2) {
        width: 32%;
        margin-right: 3%;

        @include respond-above(lg) {
          width: 27%;
        }

        @include respond-above(sm) {
          width: 50%;
          margin: 0;
        }
      }

      &:nth-child(3) {
        width: 25%;

        @include respond-above(lg) {
          width: 40%;
        }

        @include respond-above(sm) {
          width: 100%;
          margin-top: get-vw-sm(100px);

          @media (orientation: landscape) {
            margin-top: get-vw-sm(30px);
          }
        }
      }

      .icon {
        width: get-vw(25px);
        height: get-vw(25px);
        margin-right: get-vw(11px);

        @include respond-above(lg) {
          width: get-vw-lg(23px);
          height: get-vw-lg(23px);
          margin-right: get-vw-lg(9px);
        }

        @include respond-above(sm) {
          width: get-vw-sm(23px);
          height: get-vw-sm(23px);
          margin-right: get-vw-sm(9px);
        }

        img {
          width: 100%;
        }

        > div {
          width: 100%;
        }
      }

      .text {
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(30px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ff274f;
        text-transform: uppercase;

        @include respond-above(lg) {
          font-size: get-vw-lg(24px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(24px);
        }
      }

      .dop-text {
        flex-shrink: 0;
        width: 100%;
        margin-top: get-vw(15px);
        margin-left: get-vw(36px);
        font-family: NotoMono, sans-serif;
        font-size: get-vw(13px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: #ff274f;
        max-width: get-vw(220px);

        @include respond-above(lg) {
          margin-top: get-vw-lg(15px);
          margin-left: get-vw-lg(33px);
          font-size: get-vw-lg(13px);
          max-width: get-vw-lg(220px);
        }

        @include respond-above(sm) {
          max-width: get-vw-sm(266px);
          margin-top: get-vw-sm(15px);
          margin-left: get-vw-sm(33px);
          font-size: get-vw-sm(13px);
        }
      }
    }
  }
}

.main-team {
  padding: get-vw(100px) get-vw(240px);

  @include respond-above(lg) {
    padding: get-vw-lg(100px) get-vw-lg(110px);
  }

  @include respond-above(sm) {
    padding: get-vw-sm(30px) get-vw-sm(15px) get-vw-sm(100px) get-vw-sm(15px);
  }

  .main-team-items {
    display: flex;
    flex-wrap: wrap;
    margin: get-vw(-3px);

    @include respond-above(lg) {
      margin: get-vw-lg(-3px);
    }

    @include respond-above(sm) {
      margin: get-vw-sm(-4px);
      padding: 0 get-vw-sm(48px);
    }

    .main-team-item {
      width: get-vw(316px);
      flex-shrink: 0;
      margin: get-vw(3px);
      position: relative;

      @include respond-above(lg) {
        width: get-vw-lg(168px);
        margin: get-vw-lg(3px);
      }

      @include respond-above(sm) {
        width: 100%;
        margin: get-vw-sm(4px);
      }

      .photo {
        width: 100%;
        height: get-vw(320px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        background-color: #0d1719;

        @include respond-above(lg) {
          height: get-vw-lg(170px);
        }

        @include respond-above(sm) {
          height: get-vw-sm(252px);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          &.static {
            display: block;
            position: relative;
            z-index: 1;
          }

          &.hover {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            opacity: 0;
            z-index: 2;
          }

          &.hover2 {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            opacity: 0;
            z-index: 3;
          }
        }
      }

      &.hover {
        .text {
          color: #ff274f;
        }

        .photo {
          img {
            &.hover {
              opacity: 1;
            }
          }
        }
      }

      &.hover2 {
        .photo {
          img {
            &.hover2 {
              opacity: 1;
            }
          }
        }
      }

      .text {
        position: absolute;
        z-index: 2;
        left: get-vw(20px);
        bottom: get-vw(20px);
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(12px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        transition: .2s ease-in-out color;

        @include respond-above(lg) {
          left: get-vw-lg(15px);
          bottom: get-vw-lg(15px);
          font-size: get-vw-lg(12px);
        }

        @include respond-above(sm) {
          left: get-vw-sm(15px);
          bottom: get-vw-sm(15px);
          font-size: get-vw-sm(12px);
        }
      }
    }
  }

  .text-block {
    margin-top: get-vw(43px);
    width: get-vw(720px);

    @include respond-above(lg) {
      width: 100%;
      margin-top: get-vw-lg(40px);
    }

    @include respond-above(sm) {
      margin-top: get-vw-sm(60px);
    }
  }

  .team-positions {
    margin-top: get-vw(24px);

    @include respond-above(lg) {
      margin-top: get-vw-lg(30px);
    }

    @include respond-above(sm) {
      margin-top: get-vw-sm(60px);
    }

    .team-position {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: get-vw(10px) 0;
      margin-bottom: get-vw(8px);
      position: relative;

      @include respond-above(lg) {
        padding: get-vw-lg(10px) 0;
        margin-bottom: get-vw-lg(8px);
      }

      @include respond-above(sm) {
        display: flex;
        flex-wrap: wrap;
        padding: get-vw-sm(10px) 0;
        margin-bottom: get-vw-sm(12px);
      }

      @media screen and (min-width: 1110px) {
        &:hover {
          .position {
            color: #ff274f;
          }

          .name {
            color: #ff274f;
          }

          .line {
            height: get-vw(3px);

            .hover {
              opacity: 1;
            }
          }
        }
      }

      .line {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: get-vw(1px);
        background: #545353;
        transition: .2s ease-in-out height;
        z-index: 1;
        overflow: hidden;

        @include respond-above(lg) {
          height: get-vw-lg(1px);
        }

        @include respond-above(sm) {
          height: get-vw-sm(1px);
        }

        .hover {
          width: 80%;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(90deg, rgba(255, 39, 79, 0) 0%, rgba(255, 39, 79, 1) 20%, rgba(255, 39, 79, 1) 80%, rgba(255, 39, 79, 0) 100%);
          transition: .2s ease-in-out opacity;
          z-index: 2;
          opacity: 0;

          @include respond-above(lg) {
            display: none;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .position {
        font-family: NotoMono, sans-serif;
        font-size: get-vw(13px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
        margin-right: get-vw(8px);
        position: relative;
        top: get-vw(-3px);
        transition: .2s ease-in-out color;

        @include respond-above(lg) {
          font-size: get-vw-lg(13px);
          margin-right: get-vw-lg(8px);
          top: get-vw-lg(-3px);
        }

        @include respond-above(sm) {
          order: 1;
          width: 100%;
          position: static;
          margin-right: 0;
          font-size: get-vw-sm(13px);
          margin-top: get-vw-sm(2px);
        }
      }

      .name {
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(30px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
        width: get-vw(313px);
        transition: .2s ease-in-out color;

        @include respond-above(lg) {
          font-size: get-vw-lg(26px);
          width: get-vw-lg(250px);
        }

        @include respond-above(sm) {
          width: 100%;
          font-size: get-vw-sm(26px);
          order: 0;
        }
      }
    }
  }

  .button-wrap {
    margin-top: get-vw(60px);

    @include respond-above(lg) {
      margin-top: get-vw-lg(60px);
    }

    @include respond-above(sm) {
      margin-top: get-vw-sm(60px);

      .button {
        width: 100%;
      }
    }
  }
}

h3 {
  font-family: 'Basis Grotesque Pro', sans-serif;
  font-size: get-vw(30px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;

  @include respond-above(lg) {
    font-size: get-vw-lg(26px);
  }

  @include respond-above(sm) {
    font-size: get-vw-sm(26px);
    line-height: 1.38;
  }
}

.text-inline-tag {
  .tag-label {
    margin-right: get-vw(22px);
    position: relative;
    top: get-vw(-7px);

    @include respond-above(lg) {
      margin-right: get-vw-lg(15px);
      top: get-vw-lg(-5px);
    }

    @include respond-above(sm) {
      margin-right: get-vw-sm(15px);
      top: get-vw-sm(-5px);
    }
  }
}

.button {
  width: get-vw(318px);
  height: get-vw(90px);
  border-radius: get-vw(10px);
  background-color: #0a0a0a;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  padding: get-vw(11px);
  box-sizing: border-box;
  position: relative;
  transition: .2s ease-in-out background;

  @include respond-above(lg) {
    width: get-vw-lg(250px);
    height: get-vw-lg(90px);
    border-radius: get-vw-lg(10px);
    padding: get-vw-lg(10px);
  }

  @include respond-above(sm) {
    width: get-vw-sm(300px);
    height: get-vw-sm(98px);
    border-radius: get-vw-sm(10px);
    padding: get-vw-sm(11px);
  }

  &:hover {
    background: #000000;

    span {
      opacity: .6;

      &:after {
        bottom: 0;
      }
    }

    svg {
      opacity: .6;
      right: 0!important;
    }
  }

  span {
    font-family: NotoMono, sans-serif;
    font-size: get-vw(16px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    position: relative;
    transition: .2s ease-in-out opacity;

    @include respond-above(lg) {
      font-size: get-vw-lg(16px);
    }

    @include respond-above(sm) {
      font-size: get-vw-sm(16px);
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: get-vw(1px);
      bottom: get-vw(-2px);
      background: #ffffff;
      transition: .2s ease-in-out bottom;

      @include respond-above(lg) {
        height: get-vw-lg(1px);
        bottom: get-vw-lg(-2px);
      }

      @include respond-above(sm) {
        height: get-vw-sm(1px);
        bottom: get-vw-sm(-2px);
      }
    }
  }

  svg {
    width: get-vw(38px);
    height: get-vw(43px);
    position: absolute;
    top: get-vw(5px);
    right: get-vw(10px);
    transition: .2s ease-in-out right;

    @include respond-above(lg) {
      width: get-vw-lg(38px);
      height: get-vw-lg(43px);
      top: get-vw-lg(2px);
      right: get-vw-lg(10px);
    }

    @include respond-above(sm) {
      width: get-vw-sm(38px);
      height: get-vw-sm(43px);
      top: get-vw-sm(2px);
      right: get-vw-sm(10px);
    }
  }
}

.main-text-items {
  padding: get-vw(150px) get-vw(30px) get-vw(100px) get-vw(30px);
  overflow: hidden;

  @include respond-above(lg) {
    padding: get-vw-lg(100px) get-vw-lg(30px) 0 get-vw-lg(30px);
  }

  @include respond-above(sm) {
    padding: get-vw-sm(100px) get-vw-sm(15px) 0 get-vw-sm(15px);
  }

  .text {
    padding: get-vw(100px) 0;
    font-family: 'Basis Grotesque Pro', sans-serif;
    font-size: get-vw(48px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.3);
    position: relative;

    @include respond-above(lg) {
      padding: get-vw-lg(80px) 0;
      font-size: get-vw-lg(26px);
    }

    @include respond-above(sm) {
      padding: get-vw-sm(80px) 0;
      font-size: get-vw-sm(26px);
      line-height: 1.38;
    }

    &:first-child {
      transform: none;
      padding-top: get-vw(67px);

      @include respond-above(lg) {
        padding-top: get-vw-lg(60px);
      }

      @include respond-above(sm) {
        padding-top: get-vw-sm(60px);
      }
    }

    .line {
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: #ffffff;
      top: 0;
      z-index: 1;
    }

    .line-dop {
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: #ffffff;
      z-index: 1;
      transform-origin: top center;
    }

    .tag-label {
      top: get-vw(-12px);
      margin-right: get-vw(30px);

      @include respond-above(lg) {
        margin-right: get-vw-lg(20px);
        top: get-vw-lg(-5px);
      }

      @include respond-above(sm) {
        margin-right: get-vw-sm(20px);
        top: get-vw-sm(-5px);
      }
    }
  }
}

.main-capabilities {
  margin: get-vw(100px) 0;

  @include respond-above(lg) {
    margin: get-vw-lg(100px) 0;
  }

  @include respond-above(sm) {
    margin: get-vw-sm(100px) 0;
  }

  .capabilities-wrap {
    height: get-vw(640px);
    border-radius: get-vw(30px);
    background-color: #ffffff;
    margin: get-vw(6px);
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    &.with-desc {
      height: get-vw(788px);

      @include respond-above(lg) {
        height: get-vw-lg(788px);
      }

      @include respond-above(sm) {
        height: get-vw-sm(1243px);
      }
    }

    @include respond-above(lg) {
      height: get-vw-lg(640px);
      border-radius: get-vw-lg(20px);
      margin: get-vw-lg(5px);
    }

    @include respond-above(sm) {
      height: get-vw-sm(1124px);
      border-radius: get-vw-sm(20px);
      margin: get-vw-sm(5px);
    }

    .desc {
      width: get-vw(420px);
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      color: #151515;
      position: absolute;
      z-index: 1;
      left: get-vw(54px);
      top: get-vw(116px);

      @include respond-above(lg) {
        width: get-vw-lg(313px);
        font-size: get-vw-lg(13px);
        left: get-vw-lg(26px);
        top: get-vw-lg(72px);
      }

      @include respond-above(sm) {
        width: auto;
        font-size: get-vw-sm(13px);
        left: get-vw-sm(26px);
        right: get-vw-sm(26px);
        top: get-vw-sm(72px);
      }
    }

    canvas {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      touch-action: unset!important;
    }

    .ball {
      display: none;
      position: absolute;
      width: get-vw(245px);
      height: get-vw(245px);
      box-shadow: -30px 62px 34px 0 rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      top: 41.6%;
      left: 62.6%;
      z-index: 2;
      box-sizing: border-box;
      will-change: transform;

      @include respond-above(lg) {
        width: get-vw-lg(180px);
        height: get-vw-lg(180px);
        top: auto;
        left: auto;
        right: get-vw-lg(9px);
        bottom: get-vw-lg(90px);
      }

      @include respond-above(sm) {
        width: get-vw-sm(180px);
        height: get-vw-sm(180px);
        right: get-vw-sm(9px);
        bottom: get-vw-sm(90px);
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-label {
      left: get-vw(54px);
      top: get-vw(54px);
      position: absolute;
      z-index: 1;

      @include respond-above(lg) {
        left: get-vw-lg(26px);
        top: get-vw-lg(26px);
      }

      @include respond-above(sm) {
        left: get-vw-sm(26px);
        top: get-vw-sm(26px);
      }
    }

    .capabilities-list {
      position: absolute;
      bottom: get-vw(50px);
      left: get-vw(54px);
      right: get-vw(54px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      z-index: 1;

      @include respond-above(lg) {
        bottom: get-vw-lg(5px);
        left: get-vw-lg(26px);
        right: get-vw-lg(26px);
      }

      @include respond-above(sm) {
        bottom: get-vw-sm(5px);
        left: get-vw-sm(26px);
        right: get-vw-sm(26px);
      }

      .capabilities-col {
        width: get-vw(280px);

        @include respond-above(lg) {
          width: 50%;
          margin-bottom: get-vw-lg(45px);
        }

        @include respond-above(sm) {
          width: 100%;
          margin-bottom: get-vw-sm(45px);
        }

        &.wide {
          width: get-vw(480px);
          text-align: right;

          @include respond-above(lg) {
            width: 50%;
            text-align: left;
          }

          @include respond-above(sm) {
            width: 100%;
          }
        }

        h2 {
          font-family: 'Basis Grotesque Pro', sans-serif;
          font-size: get-vw(30px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #151515;
          margin: 0 0 get-vw(44px) 0;

          @include respond-above(lg) {
            font-size: get-vw-lg(30px);
            margin: 0 0 get-vw-lg(18px) 0;
          }

          @include respond-above(sm) {
            font-size: get-vw-sm(30px);
            margin: 0 0 get-vw-sm(18px) 0;
          }
        }

        ul {
          li {
            font-family: NotoMono, sans-serif;
            font-size: get-vw(13px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #151515;
            transition: .2s ease-in-out color;
            position: relative;

            @include respond-above(lg) {
              font-size: get-vw-lg(13px);

              &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(21, 21, 21, 0.3);
                height: 1px;
                z-index: 1;
              }
            }

            @include respond-above(sm) {
              font-size: get-vw-sm(13px);
            }

            .line {
              position: absolute;
              width: get-vw(1320px);
              bottom: 0;
              right: 0;
              background-color: rgba(21, 21, 21, 0.3);
              height: get-vw(1px);
              z-index: 1;
              overflow: hidden;

              @include respond-above(lg) {
                display: none;
              }

              .hover {
                width: get-vw(300px);
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                background: linear-gradient(90deg, rgba(255, 39, 79, 0) 0%, rgba(255, 39, 79, 1) 20%, rgba(255, 39, 79, 1) 80%, rgba(255, 39, 79, 0) 100%);
                transition: .2s ease-in-out opacity;
                z-index: 2;
                opacity: 0;

                &.show {
                  opacity: 1;
                }
              }

              &.short {
                width: get-vw(480px);
              }
            }

            &.hover-pseudo {
              color: #ff274f;
            }
          }
        }
      }
    }
  }
}

.main-projects {
  padding: get-vw(100px) 0 0 0;

  @include respond-above(lg) {
    padding: get-vw-lg(100px) 0 0 0;
  }

  @include respond-above(sm) {
    padding: get-vw-sm(100px) 0 0 0;
  }

  .projects-tag {
    padding: 0 get-vw(30px);
    margin-bottom: get-vw(120px);

    @include respond-above(lg) {
      padding: 0 get-vw-lg(20px);
      margin-bottom: get-vw-lg(45px);
    }

    @include respond-above(sm) {
      padding: 0 get-vw-sm(20px);
      margin-bottom: get-vw-sm(45px);
    }
  }

  .projects-grid-wrap {
    padding-bottom: get-vw(100px);

    @include respond-above(lg) {
      padding-bottom: get-vw-lg(100px);
    }

    @include respond-above(sm) {
      padding-bottom: get-vw-sm(100px);

      .project-item {
        &:nth-child(4) {
          display: none;
        }

        &:nth-child(5) {
          display: none;
        }
      }
    }
  }
}

.projects-grid-wrap {
  overflow: hidden;

  @include respond-above(lg) {
    overflow: scroll;
  }

  @include respond-above(sm) {
    overflow: hidden;
  }

  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    width: get-vw(2100px);
    padding-top: get-vw(4px);
    justify-content: center;

    &.left {
      justify-content: flex-start;
    }

    @include respond-above(lg) {
      width: get-vw-lg(1044px);
      padding-top: 0;
    }

    @include respond-above(sm) {
      width: 100%;
    }

    .project-item {
      width: get-vw(500px);
      margin: 0 get-vw(100px) get-vw(160px) get-vw(100px);
      display: block;
      text-decoration: none;
      position: relative;

      @include respond-above(lg) {
        width: get-vw-lg(300px);
        margin: 0 get-vw-lg(24px) get-vw-lg(80px) get-vw-lg(24px);
      }

      @include respond-above(sm) {
        width: 100%;
        margin: 0 get-vw-sm(15px) get-vw-sm(50px) get-vw-sm(15px);
      }

      &.button {
        height: get-vw(370px);
        display: flex;
        padding: get-vw(30px);

        @include respond-above(lg) {
          height: get-vw-lg(230px);
          padding: get-vw-lg(15px);
        }

        @include respond-above(sm) {
          height: get-vw-sm(98px);
          padding: get-vw-sm(15px);
        }

        svg {
          width: get-vw(63px);
          height: get-vw(72px);
          top: get-vw(15px);
          right: get-vw(20px);

          @include respond-above(lg) {
            width: get-vw-lg(46px);
            height: get-vw-lg(53px);
            top: get-vw-lg(10px);
            right: get-vw-lg(15px);
          }

          @include respond-above(sm) {
            width: get-vw-sm(38px);
            height: get-vw-sm(43px);
            top: get-vw-sm(2px);
            right: get-vw-sm(10px);
          }
        }
      }

      &:not(.button) {
        @media screen and (min-width: 1110px) {
          &:hover {
            .img-wrap {
              padding: get-vw(2px);

              .line {
                .hover {
                  opacity: 1;
                }
              }

              &:after {
                opacity: .2;
              }
            }

            .name-number {
              padding: 0 get-vw(10px);

              h3 {
                color: #ff274f;
              }

              .number {
                color: #ff274f;
              }
            }

            .tags {
              padding: 0 get-vw(10px);

              .tag {
                color: #ff274f;
              }
            }
          }
        }

        .img-wrap {
          width: 100%;
          height: get-vw(370px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          transition: .2s ease-in-out padding;
          box-sizing: border-box;

          @include respond-above(lg) {
            height: get-vw-lg(230px);
          }

          @include respond-above(sm) {
            height: get-vw-sm(255px);
          }

          .line {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: #2A2929;
            z-index: 0;

            @include respond-above(lg) {
              display: none;
            }

            .hover {
              width: get-vw(400px);
              height: get-vw(400px);
              position: absolute;
              left: 0;
              top: 0;
              transition: .2s ease-in-out opacity;
              z-index: 2;
              background: rgb(255,39,79);
              background: radial-gradient(circle, rgba(255,39,79,1) 0, rgba(42,41,41,0) 65%);
              border-radius: 50%;
              opacity: 0;
            }
          }

          &:after {
            content: '';
            position: absolute;
            left: get-vw(2px);
            right: get-vw(2px);
            bottom: get-vw(2px);
            top: get-vw(2px);
            background-color: #000000;
            transition: .2s ease-in-out opacity;
            z-index: 2;
            opacity: 0;

            @include respond-above(lg) {
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
            }
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 1;
          }
        }

        .name-number {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: get-vw(10px);
          transition: .2s ease-in-out padding;

          @include respond-above(lg) {
            margin-top: get-vw-lg(10px);
          }

          @include respond-above(sm) {
            margin-top: get-vw-sm(10px);
          }

          h3 {
            font-family: 'Basis Grotesque Pro', sans-serif;
            font-size: get-vw(24px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: #ffffff;
            transition: .2s ease-in-out color;

            @include respond-above(lg) {
              font-size: get-vw-lg(18px);
            }

            @include respond-above(sm) {
              font-size: get-vw-sm(18px);
            }
          }

          .number {
            font-family: NotoMono, sans-serif;
            font-size: get-vw(13px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #ffffff;
            position: relative;
            top: get-vw(-10px);
            transition: .2s ease-in-out color;
            margin-left: get-vw(15px);

            @include respond-above(lg) {
              font-size: get-vw-lg(13px);
              top: get-vw-lg(-10px);
              margin-left: get-vw-lg(10px);
            }

            @include respond-above(sm) {
              font-size: get-vw-sm(13px);
              top: get-vw-sm(-10px);
              margin-left: get-vw-sm(10px);
            }
          }
        }

        .tags {
          display: flex;
          transition: .2s ease-in-out padding;
          flex-wrap: wrap;

          .tag {
            font-family: NotoMono, sans-serif;
            font-size: get-vw(13px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(255,255,255,0.3);
            margin-right: get-vw(22px);
            position: relative;
            transition: .2s ease-in-out color;

            @include respond-above(lg) {
              font-size: get-vw-lg(13px);
              margin-right: get-vw-lg(22px);
            }

            @include respond-above(sm) {
              font-size: get-vw-sm(13px);
              margin-right: get-vw-sm(22px);
            }

            &:after {
              content: '/';
              position: absolute;
              right: get-vw(-15px);
              top: 0;
              bottom: 0;

              @include respond-above(lg) {
                right: get-vw-lg(-15px);
              }

              @include respond-above(sm) {
                right: get-vw-sm(-15px);
              }
            }

            &:last-child {
              margin-right: 0;

              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

footer {
  background: #151515;
  padding: get-vw(35px) get-vw(30px) get-vw(30px) get-vw(30px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @include respond-above(lg) {
    padding: get-vw-lg(20px) get-vw-lg(15px) 0 get-vw-lg(15px);
  }

  @include respond-above(sm) {
    padding: get-vw-sm(20px) get-vw-sm(15px) 0 get-vw-sm(15px);
  }

  .lines {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    .line {
      position: absolute;

      &:first-child {
        bottom: 0;
        left: 50%;
        width: 1px;
        opacity: 0.2;
        background-color: #ffffff;
        top: 0;

        @include respond-above(lg) {
          bottom: get-vw-lg(50px);
          height: 90vh;
          top: auto;
        }

        @include respond-above(sm) {
          bottom: get-vw-sm(50px);
        }
      }

      &:last-child {
        left: 0;
        right: 0;
        bottom: get-vw(160px);
        height: 1px;
        opacity: 0.2;
        background-color: #ffffff;

        @include respond-above(lg) {
          bottom: get-vw-lg(50px);
        }

        @include respond-above(sm) {
          bottom: get-vw-sm(50px);
        }
      }
    }
  }

  .footer-top {
    padding-bottom: get-vw(18px);
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    opacity: 0;
    transition: .2s ease-in-out opacity;
    pointer-events: none;

    @include respond-above(lg) {
      padding-bottom: get-vw-lg(60px);
    }

    @include respond-above(sm) {
      padding-bottom: get-vw-sm(60px);

      @media (orientation: landscape) {
        padding-bottom: get-vw-sm(20px);
        opacity: 1;
        pointer-events: auto;
      }
    }

    &.show {
      opacity: 1;
      pointer-events: auto;
    }

    .address-menu {
      width: 50%;
      box-sizing: border-box;
      display: flex;
      padding-right: get-vw(35px);
      justify-content: space-between;

      @include respond-above(lg) {
        padding-right: get-vw-lg(15px);
        position: absolute;
        left: 0;
        top: 0;
      }

      @include respond-above(sm) {
        padding-right: get-vw-sm(15px);
      }

      .address {
        p {
          opacity: 0.3;
          font-family: 'Basis Grotesque Pro', sans-serif;
          font-size: get-vw(12px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #ffffff;
          text-transform: uppercase;

          @include respond-above(lg) {
            font-size: get-vw-lg(12px);
          }

          @include respond-above(sm) {
            font-size: get-vw-sm(12px);
          }
        }
      }

      .footer-menu {
        @include respond-above(lg) {
          display: none;
        }

        ul {
          li {
            text-align: right;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-family: NotoMono, sans-serif;
            font-size: get-vw(12px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.83;
            letter-spacing: normal;
            color: #ffffff;

            &:hover {
              .arrow {
                left: get-vw(-43px);
                opacity: 1;
              }

              &:after {
                opacity: 1;
              }

              a {
                color: #4f000f;
              }
            }

            .arrow {
              position: absolute;
              left: get-vw(-60px);
              transition: .2s ease-in-out opacity, .2s ease-in-out left;
              opacity: 0;

              svg {
                width: get-vw(16px);
                height: get-vw(18px);
              }
            }

            &:after {
              content: '';
              position: absolute;
              left: get-vw(-7px);
              right: get-vw(-7px);
              top: 0;
              bottom: 0;
              background-color: #ff274f;
              z-index: 1;
              transition: .2s ease-in-out opacity;
              opacity: 0;
            }

            a {
              font-family: NotoMono, sans-serif;
              font-size: get-vw(12px);
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.83;
              letter-spacing: normal;
              text-align: right;
              color: #ffffff;
              text-decoration: none;
              position: relative;
              z-index: 2;
              transition: .2s ease-in-out color;
            }
          }
        }
      }
    }

    .socials-links {
      width: 50%;
      box-sizing: border-box;
      padding-left: get-vw(30px);

      @include respond-above(lg) {
        padding-left: get-vw-lg(15px);
        width: 100%;
      }

      @include respond-above(sm) {
        padding-left: get-vw-sm(15px);
      }

      .socials {
        display: flex;
        justify-content: flex-end;

        ul {
          li {
            font-family: 'Basis Grotesque Pro', sans-serif;
            font-size: get-vw(12px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: right;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            @include respond-above(lg) {
              font-size: get-vw-lg(13px);
              line-height: 1.85;
            }

            @include respond-above(sm) {
              font-size: get-vw-sm(13px);
            }

            @media screen and (min-width: 1110px) {
              &:hover {
                .svg {
                  left: get-vw(-35px);
                  opacity: 1;
                }

                a {
                  color: #ff274f;
                }
              }
            }

            .svg {
              width: get-vw(20px);
              height: get-vw(20px);
              position: absolute;
              left: get-vw(-50px);
              transition: .2s ease-in-out opacity, .2s ease-in-out left;
              opacity: 0;

              @include respond-above(lg) {
                display: none;
              }

              svg {
                width: get-vw(20px);
                height: get-vw(20px);
              }
            }

            a {
              font-family: 'Basis Grotesque Pro', sans-serif;
              font-size: get-vw(12px);
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: right;
              color: #ffffff;
              text-decoration: none;
              transition: .2s ease-in-out color;

              @include respond-above(lg) {
                font-size: get-vw-lg(13px);
                line-height: 1.85;
              }

              @include respond-above(sm) {
                font-size: get-vw-sm(13px);
              }
            }
          }
        }
      }

      .links {
        margin-top: get-vw(60px);

        @include respond-above(lg) {
          margin-top: get-vw-lg(60px);
        }

        @include respond-above(sm) {
          margin-top: get-vw-sm(60px);

          @media (orientation: landscape) {
            margin-top: get-vw-sm(20px);
          }
        }

        .link {
          text-align: right;
        }

        a {
          font-family: 'Basis Grotesque Pro', sans-serif;
          font-size: get-vw(64px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.16;
          letter-spacing: normal;
          text-align: right;
          color: #ffffff;
          text-transform: uppercase;
          text-decoration: none;
          white-space: nowrap;

          @include respond-above(lg) {
            font-size: get-vw-lg(33px);
          }

          @include respond-above(sm) {
            font-size: get-vw-sm(33px);

            @media (orientation: landscape) {
              font-size: get-vw-sm(20px);
            }
          }

          &:hover {
            color: #FF274F;
          }
        }
      }
    }
  }

  .footer-bottom {
    height: get-vw(130px);
    padding-top: get-vw(10px);
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    z-index: 2;

    @include respond-above(lg) {
      height: get-vw-lg(50px);
      padding-top: get-vw-lg(15px);
    }

    @include respond-above(sm) {
      height: get-vw-sm(50px);
      padding-top: get-vw-sm(15px);
    }

    .copyright {
      width: 50%;
      box-sizing: border-box;

      @include respond-above(lg) {
        width: 100%;
      }

      p {
        opacity: 0.3;
        font-family: NotoMono, sans-serif;
        font-size: get-vw(12px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;

        @include respond-above(lg) {
          font-size: get-vw-lg(12px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(12px);
        }
      }
    }

    .call {
      width: 50%;
      display: flex;
      justify-content: space-between;
      padding-left: get-vw(35px);
      box-sizing: border-box;

      @include respond-above(lg) {
        display: none;
      }

      p {
        opacity: 0.3;
        font-family: NotoMono, sans-serif;
        font-size: get-vw(12px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }

      .qr {
        width: get-vw(100px);
        height: get-vw(100px);
        margin-top: get-vw(20px);

        img {
          width: 100%;
        }
      }
    }
  }
}

.hide-lg {
  display: block;

  @include respond-above(lg) {
    display: none;
  }
}

.hide-sm {
  display: block;

  @include respond-above(sm) {
    display: none;
  }
}

.show-lg {
  display: none;

  @include respond-above(lg) {
    display: block;
  }
}

.show-sm {
  display: none;

  @include respond-above(sm) {
    display: block;
  }
}

.about-header-wrap {
  padding-top: get-vw(6px);
  overflow: hidden;
  border-radius: 0 0 get-vw(30px) get-vw(30px);
  margin: 0 get-vw(6px) get-vw(50px) get-vw(6px);

  @include respond-above(lg) {
    padding-top: get-vw-lg(5px);
    border-radius: 0 0 get-vw-lg(20px) get-vw-lg(20px);
    margin: 0 get-vw-lg(5px) get-vw-lg(50px) get-vw-lg(5px);
  }

  @include respond-above(sm) {
    padding-top: get-vw-sm(5px);
    border-radius: 0 0 get-vw-sm(20px) get-vw-sm(20px);
    margin: 0 get-vw-sm(5px) get-vw-sm(50px) get-vw-sm(5px);
  }
}

.about-header {
  background-color: #535353;
  position: relative;
  border-radius: get-vw(30px) get-vw(30px) 0 0;

  @include respond-above(lg) {
    border-radius: get-vw-lg(20px) get-vw-lg(20px) 0 0;
  }

  @include respond-above(sm) {
    border-radius: get-vw-sm(20px) get-vw-sm(20px) 0 0;

    @media (orientation: landscape) {
      min-height: get-vw-sm(500px);
    }
  }

  .lines {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;

    .line {
      background-color: #ffffff;
      position: absolute;

      &:first-child {
        width: 1px;
        left: 50%;
        top: 0;
        bottom: 0;
      }

      &:nth-child(2) {
        top: get-vw(250px);
        left: 0;
        right: 0;
        height: 1px;

        @include respond-above(lg) {
          top: get-vw-lg(220px);
        }

        @include respond-above(sm) {
          top: get-vw-sm(172px);
        }
      }
    }
  }

  .title {
    position: absolute;
    left: get-vw(24px);
    top: get-vw(165px);
    z-index: 3;

    @include respond-above(lg) {
      left: get-vw-lg(10px);
      top: get-vw-lg(175px);
    }

    @include respond-above(sm) {
      left: get-vw-sm(10px);
      top: get-vw-sm(125px);
    }

    h1 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(64px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.16;
      letter-spacing: normal;
      color: #ffffff;
      text-transform: uppercase;

      @include respond-above(lg) {
        font-size: get-vw-lg(34px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(34px);
      }
    }
  }

  .text {
    position: absolute;
    right: get-vw(24px);
    bottom: get-vw(30px);
    z-index: 3;
    width: get-vw(654px);

    @include respond-above(lg) {
      right: get-vw-lg(10px);
      bottom: get-vw-lg(20px);
      width: 46%;
    }

    @include respond-above(sm) {
      right: get-vw-sm(10px);
      bottom: get-vw-sm(20px);
      width: auto;
      left: get-vw-sm(10px);
    }

    h3 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(48px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: rgba(255,255,255,0.5);
      margin: 0 0 get-vw(50px) 0;

      @include respond-above(lg) {
        font-size: get-vw-lg(26px);
        margin: 0 0 get-vw-lg(30px) 0;
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(26px);
        margin: 0 0 get-vw-sm(30px) 0;
        color: rgba(255,255,255,0.8);
      }

      .tag-label {
        top: get-vw(-13px);

        @include respond-above(lg) {
          top: get-vw-lg(-4px);
        }

        @include respond-above(sm) {
          top: get-vw-sm(-4px);
        }
      }
    }
  }

  .bg {
    position: relative;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 92%;
      background-image: linear-gradient(to bottom, rgba(83, 83, 83, 0), rgba(82, 82, 82, 0.01), rgba(34, 34, 34, 0.6) 97%);
    }

    img {
      width: 100%;
    }
  }
}

.about-approach {
  padding: get-vw(80px) get-vw(240px) get-vw(100px) get-vw(240px);
  position: relative;

  @include respond-above(lg) {
    padding: get-vw-lg(80px) get-vw-lg(15px) get-vw-lg(100px) get-vw-lg(15px);
  }

  @include respond-above(sm) {
    padding: get-vw-sm(80px) get-vw-sm(15px) get-vw-sm(100px) get-vw-sm(15px);
  }

  .lines {
    @include respond-above(lg) {
      display: none;
    }

    .line {
      width: 1px;
      height: get-vw(49px);
      background-color: #ff274f;
      position: absolute;
      top: get-vw(177px);

      &:first-child {
        left: get-vw(240px);
      }

      &:last-child {
        right: get-vw(240px);
      }
    }
  }

  .title {
    margin-bottom: get-vw(22px);

    @include respond-above(lg) {
      margin-bottom: get-vw-lg(25px);
    }

    @include respond-above(sm) {
      margin-bottom: get-vw-sm(25px);
    }

    h2 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(60px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ff274f;
      text-transform: uppercase;
      text-align: center;

      @include respond-above(lg) {
        font-size: get-vw-lg(32px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(32px);
      }
    }
  }

  .text {
    padding: 0 get-vw(180px);
    margin-bottom: get-vw(40px);

    @include respond-above(lg) {
      padding: 0 get-vw-lg(100px);
      margin-bottom: get-vw-lg(45px);
    }

    @include respond-above(sm) {
      padding: 0;
      margin-bottom: get-vw-sm(45px);
    }

    p {
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #ff274f;

      @include respond-above(lg) {
        font-size: get-vw-lg(13px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(13px);
      }
    }
  }

  .logo {
    text-align: center;

    img {
      width: get-vw(90px);
      height: get-vw(90px);

      @include respond-above(lg) {
        width: get-vw-lg(90px);
        height: get-vw-lg(90px);
      }

      @include respond-above(sm) {
        width: get-vw-sm(90px);
        height: get-vw-sm(90px);
      }
    }
  }
}

.about-text-two-columns {
  padding: get-vw(100px) 0;
  display: flex;
  flex-wrap: wrap;

  @include respond-above(lg) {
    padding: get-vw-lg(100px) 0;
  }

  @include respond-above(sm) {
    padding: get-vw-sm(100px) get-vw-sm(15px);
  }

  .left {
    margin-left: get-vw(240px);
    width: get-vw(360px);
    margin-top: get-vw(10px);

    @include respond-above(lg) {
      margin-left: get-vw-lg(15px);
      width: get-vw-lg(300px);
      margin-top: get-vw-lg(5px);
    }

    @include respond-above(sm) {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
    }

    p {
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      color: #ff274f;
      margin-bottom: get-vw(30px);

      @include respond-above(lg) {
        font-size: get-vw-lg(13px);
        margin-bottom: get-vw-lg(30px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(13px);
        margin-bottom: get-vw-sm(30px);
      }
    }
  }

  .right {
    margin-left: get-vw(156px);
    width: get-vw(610px);

    @include respond-above(lg) {
      margin-left: get-vw-lg(30px);
      width: get-vw-lg(370px);
    }

    @include respond-above(sm) {
      width: 100%;
      margin-left: 0;
      margin-top: get-vw-sm(50px);
    }

    h3 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(48px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: rgba(255,255,255,0.3);

      @include respond-above(lg) {
        font-size: get-vw-lg(26px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(26px);
        color: rgba(255,255,255,0.4);
      }

      .tag-label {
        top: get-vw(-13px);

        @include respond-above(lg) {
          top: get-vw-lg(-4px);
        }

        @include respond-above(sm) {
          top: get-vw-sm(-4px);
        }
      }
    }

    .img {
      margin-top: get-vw(90px);

      @include respond-above(lg) {
        margin-top: get-vw-lg(50px);
      }

      @include respond-above(sm) {
        margin-top: get-vw-sm(50px);
      }

      img {
        display: block;
        width: get-vw(504px);

        @include respond-above(lg) {
          width: get-vw-lg(298px);
        }

        @include respond-above(sm) {
          width: get-vw-sm(298px);
        }
      }
    }
  }
}

.about-scheme {
  padding: get-vw(100px) get-vw(30px);

  @include respond-above(lg) {
    padding: get-vw-lg(100px) get-vw-lg(15px);
  }

  @include respond-above(sm) {
    padding: get-vw-sm(100px) get-vw-sm(15px);
  }

  .text {
    padding-top: get-vw(67px);
    border-top: get-vw(1px) solid #ffffff;
    padding-bottom: get-vw(60px);

    @include respond-above(lg) {
      padding-top: get-vw-lg(59px);
      border-width: get-vw-lg(1px);
      padding-bottom: get-vw-lg(200px);
    }

    @include respond-above(sm) {
      padding-top: get-vw-sm(59px);
      border-width: get-vw-sm(1px);
      padding-bottom: get-vw-sm(200px);
    }

    h3 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(48px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: rgba(255,255,255,0.3);

      @include respond-above(lg) {
        font-size: get-vw-lg(26px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(26px);
        color: rgba(255,255,255,0.4);
      }

      .tag-label {
        top: get-vw(-13px);

        @include respond-above(lg) {
          top: get-vw-lg(-4px);
        }

        @include respond-above(sm) {
          top: get-vw-sm(-4px);
        }
      }
    }
  }
}

.scheme {
  display: flex;
  flex-wrap: wrap;

  @include respond-above(lg) {
    position: relative;
    padding-right: 30%;

    &:after {
      content: '';
      width: 1px;
      position: absolute;
      left: 0;
      top: get-vw-lg(20px);
      bottom: 0;
      background-image: linear-gradient(to bottom, #7f7e7e 15%, #ff274f 38%);
    }
  }

  @include respond-above(sm) {
    padding-right: 0;

    &:after {
      top: get-vw-sm(20px);
    }
  }

  .scheme-item {
    width: 25%;
    margin-bottom: get-vw(5px);
    position: relative;

    @include respond-above(lg) {
      width: 100%;
      padding-left: get-vw-lg(15px);
      margin-bottom: get-vw-lg(55px);

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include respond-above(sm) {
      padding-left: get-vw-sm(15px);
      margin-bottom: get-vw-sm(55px);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:nth-child(1) {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: get-vw(1380px);
        height: 1px;
        background-image: linear-gradient(to right, #7f7e7e 38%, #ff274f 63%);
        top: get-vw(168px);

        @include respond-above(lg) {
          display: none;
        }
      }
    }

    &:nth-child(5) {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: get-vw(875px);
        height: 1px;
        background: #ff274f;
        top: get-vw(167px);

        @include respond-above(lg) {
          display: none;
        }
      }
    }

    &.active {
      .count {
        color: #ff274f;
      }

      .name {
        color: #ff274f;

        &:after {
          background: #ff274f;
        }
      }

      .desc {
        color: #ff274f;
      }
    }

    .img {
      padding-left: get-vw(22px);
      margin-bottom: get-vw(-4px);
      height: get-vw(57px);

      @include respond-above(lg) {
        height: auto;
        padding-left: get-vw-lg(22px);
        margin-bottom: get-vw-lg(-4px);

        img {
          height: get-vw-lg(56px);
        }
      }

      @include respond-above(sm) {
        padding-left: get-vw-sm(22px);
        margin-bottom: get-vw-sm(-4px);

        img {
          height: get-vw-sm(56px);
        }
      }

      &.big {
        height: get-vw(110px);

        @include respond-above(lg) {
          height: auto;

          img {
            height: get-vw-lg(106px);
          }
        }

        @include respond-above(sm) {
          img {
            height: get-vw-sm(106px);
          }
        }
      }

      img {
        height: 100%;
      }
    }

    .count {
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7f7e7e;
      margin-bottom: get-vw(-3px);

      @include respond-above(lg) {
        font-size: get-vw-lg(13px);
        margin-bottom: get-vw-lg(-3px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(13px);
        margin-bottom: get-vw-sm(-3px);
      }
    }

    .name {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(24px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #7f7e7e;
      text-transform: uppercase;
      padding-left: get-vw(22px);
      position: relative;
      margin-bottom: get-vw(17px);

      @include respond-above(lg) {
        font-size: get-vw-lg(24px);
        padding-left: get-vw-lg(22px);
        margin-bottom: get-vw-lg(2px);
        display: inline-flex;
        padding-right: get-vw-lg(20px);
        background: #2A2929;
        position: relative;
        z-index: 2;
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(24px);
        padding-left: get-vw-sm(22px);
        margin-bottom: get-vw-sm(2px);
        padding-right: get-vw-sm(15px);
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: get-vw(14px);
        width: get-vw(12px);
        height: get-vw(12px);
        background-color: #7f7e7e;
        border-radius: 50%;

        @include respond-above(lg) {
          width: get-vw-lg(12px);
          height: get-vw-lg(12px);
          top: get-vw-lg(14px);
        }

        @include respond-above(sm) {
          width: get-vw-sm(12px);
          height: get-vw-sm(12px);
          top: get-vw-sm(14px);
        }
      }
    }

    .desc {
      padding-left: get-vw(22px);
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      color: #7f7e7e;
      max-width: get-vw(230px);

      @include respond-above(lg) {
        padding-left: get-vw-lg(22px);
        font-size: get-vw-lg(13px);
        max-width: get-vw-lg(230px);
      }

      @include respond-above(sm) {
        padding-left: get-vw-sm(22px);
        font-size: get-vw-sm(13px);
        max-width: get-vw-sm(230px);
      }
    }

    .sep-4-mob {
      display: none;

      @include respond-above(lg) {
        display: block ;
        position: absolute;
        left: get-vw-lg(50px);
        right: 0;
        top: get-vw-lg(28px);
        width: auto;
        height: get-vw-lg(100px);
        border: 1px solid #ff274f;
        border-radius: 0 get-vw-lg(100px) 0 0;
        border-bottom: none;
        border-left: none;
      }

      @include respond-above(sm) {
        left: get-vw-sm(50px);
        height: get-vw-sm(100px);
        border-radius: 0 get-vw-sm(50px) 0 0;
        top: get-vw-sm(26px);
      }
    }

    .sep {
      position: absolute;
      left: get-vw(185px);
      top: get-vw(-43px);
      width: get-vw(165px);
      height: get-vw(210px);
      border: 1px solid #ff274f;
      border-radius: 0 0 get-vw(150px) 0;
      border-top: none;
      border-left: none;

      @include respond-above(lg) {
        left: get-vw-lg(50px);
        right: 0;
        width: auto;
        height: get-vw-lg(100px);
        border-radius: 0 0 get-vw-lg(100px) 0;
        top: get-vw-lg(-70px);
      }

      @include respond-above(sm) {
        left: get-vw-sm(50px);
        height: get-vw-sm(100px);
        border-radius: 0 0 get-vw-sm(50px) 0;
        top: get-vw-sm(-74px);
      }

      img {
        width: get-vw(49px);
        height: get-vw(49px);
        display: block;
        position: absolute;
        right: 0;
        bottom: 20%;

        @include respond-above(lg) {
          width: get-vw-lg(34px);
          height: get-vw-lg(34px);
          right: 1%;
          bottom: 18%;
        }

        @include respond-above(sm) {
          width: get-vw-sm(34px);
          height: get-vw-sm(34px);
          bottom: -4%;
          right: 0;
        }
      }
    }
  }
}

.about-cta {
  padding: get-vw(100px) get-vw(30px) get-vw(100px) get-vw(756px);

  @include respond-above(lg) {
    padding: get-vw-lg(100px) get-vw-lg(15px) get-vw-lg(100px) 40%;
  }

  @include respond-above(sm) {
    padding: get-vw-sm(100px) get-vw-sm(15px) get-vw-sm(100px) get-vw-sm(15px);
  }

  p {
    font-family: 'Basis Grotesque Pro', sans-serif;
    font-size: get-vw(48px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #ff274f;
    margin: 0;

    @include respond-above(lg) {
      font-size: get-vw-lg(26px);
      line-height: 1.38;
    }

    @include respond-above(sm) {
      font-size: get-vw-sm(26px);
    }
  }

  .button-wrap {
    margin: get-vw(50px) 0 0 0;

    @include respond-above(lg) {
      margin: get-vw-lg(40px) 0 0 0;
    }

    @include respond-above(sm) {
      margin: get-vw-sm(40px) 0 0 0;
    }

    .button {
      width: get-vw(504px);
      height: get-vw(143px);

      @include respond-above(lg) {
        width: get-vw-lg(345px);
        height: get-vw-lg(120px);
      }

      @include respond-above(sm) {
        width: 100%;
        height: get-vw-sm(120px);
      }
    }
  }
}

.about-rewards {
  padding: get-vw(100px) get-vw(30px);

  @include respond-above(lg) {
    padding: get-vw-lg(100px) get-vw-lg(15px);
  }

  @include respond-above(sm) {
    padding: get-vw-sm(100px) get-vw-sm(15px);
  }

  .text {
    margin-bottom: get-vw(90px);

    @include respond-above(lg) {
      margin-bottom: get-vw-lg(80px);
    }

    @include respond-above(sm) {
      margin-bottom: get-vw-sm(80px);
    }

    h3 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(48px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: rgba(255,255,255,0.3);

      @include respond-above(lg) {
        font-size: get-vw-lg(26px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(26px);
        color: rgba(255,255,255,0.4);
      }

      .tag-label {
        top: get-vw(-13px);

        @include respond-above(lg) {
          top: get-vw-lg(-4px);
        }

        @include respond-above(sm) {
          top: get-vw-sm(-4px);
        }
      }
    }
  }

  .rewards-list {
    .reward-item {
      text-decoration: none;
      display: flex;
      align-items: center;
      position: relative;
      padding: get-vw(7px) 0;
      margin-bottom: get-vw(8px);

      @include respond-above(lg) {
        padding: get-vw-lg(8px) 0;
        margin-bottom: get-vw-lg(8px);
      }

      @include respond-above(sm) {
        padding: 0;
        margin-bottom: 0;
      }

      @media screen and (min-width: 1110px) {
        &:hover {
          .year {
            color: #ff274f;
          }

          .name {
            color: #ff274f;
          }

          .reward {
            color: #ff274f;

            .icon {
              opacity: 1;
            }
          }

          .arrow {
            transform: translateX(0);
            opacity: 1;
          }

          .line {
            height: get-vw(3px);

            .hover {
              opacity: 1;
            }
          }
        }
      }

      .year {
        width: get-vw(79px);
        font-family: NotoMono, sans-serif;
        font-size: get-vw(13px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        transition: .2s ease-in-out color;
        flex-shrink: 0;
        margin-top: get-vw(12px);

        @include respond-above(lg) {
          font-size: get-vw-lg(13px);
          width: get-vw-lg(50px);
          margin-top: get-vw-lg(5px);
          color: #ff274f;
        }
        @include respond-above(sm) {
          font-size: get-vw-sm(13px);
          width: get-vw-sm(50px);
          margin-top: get-vw-sm(15px);
        }
      }

      .name {
        width: get-vw(530px);
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(30px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        transition: .2s ease-in-out color;
        flex-shrink: 0;

        @include respond-above(lg) {
          width: get-vw-lg(290px);
          font-size: get-vw-lg(22px);
          color: #ff274f;
        }

        @include respond-above(sm) {
          width: 100%;
          flex-shrink: 1;
          font-size: get-vw-sm(22px);
          margin-top: get-vw-sm(10px);
        }
      }

      .reward {
        margin-left: get-vw(116px);
        width: 100%;
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(30px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        position: relative;
        display: flex;
        align-items: center;
        transition: .2s ease-in-out color;

        @include respond-above(lg) {
          margin-left: get-vw-lg(25px);
          font-size: get-vw-lg(22px);
          color: #ff274f;
        }

        @include respond-above(sm) {
          margin-left: get-vw-sm(25px);
          font-size: get-vw-sm(22px);
          width: get-vw-sm(54px);
          flex-shrink: 0;

          span {
            display: none;
          }
        }

        .icon {
          position: absolute;
          left: get-vw(-100px);
          transition: .2s ease-in-out opacity;
          top: get-vw(-16px);
          opacity: 0;

          @include respond-above(lg) {
            opacity: 1;
            left: get-vw-lg(-70px);
            top: get-vw-lg(-17px);
          }

          @include respond-above(sm) {
            position: static;
          }

          img {
            width: get-vw(65px);

            @include respond-above(lg) {
              width: get-vw-lg(54px);
            }

            @include respond-above(sm) {
              width: get-vw-sm(54px);
            }
          }
        }
      }

      .arrow {
        transform: translateX(-30px);
        transition: .2s ease-in-out opacity, .2s ease-in-out transform;
        opacity: 0;

        @include respond-above(lg) {
          display: none;
        }
      }

      .line {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: get-vw(1px);
        background: #545353;
        transition: .2s ease-in-out height;
        z-index: 1;
        overflow: hidden;

        @include respond-above(lg) {
          height: get-vw-lg(1px);
        }

        @include respond-above(sm) {
          height: get-vw-sm(1px);
        }

        .hover {
          width: 55%;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(90deg, rgba(255, 39, 79, 0) 0%, rgba(255, 39, 79, 1) 20%, rgba(255, 39, 79, 1) 80%, rgba(255, 39, 79, 0) 100%);
          transition: .2s ease-in-out opacity;
          z-index: 2;
          opacity: 0;

          @include respond-above(lg) {
            display: none;
          }
        }
      }
    }
  }
}

.about-clients {
  padding: get-vw(100px) get-vw(30px) get-vw(50px) get-vw(30px);

  @include respond-above(lg) {
    padding: get-vw-lg(100px) get-vw-lg(15px) get-vw-lg(50px) get-vw-lg(15px);
  }

  @include respond-above(sm) {
    padding: get-vw-sm(100px) get-vw-sm(15px) get-vw-sm(50px) get-vw-sm(15px);
  }

  .tag-label {
    margin-bottom: get-vw(40px);

    @include respond-above(lg) {
      margin-bottom: get-vw-lg(30px);
    }

    @include respond-above(sm) {
      margin-bottom: get-vw-sm(30px);
    }
  }

  .clients-list {
    .client-item {
      width: 25%;
      background: var(--bg);
      text-decoration: none;
      border-radius: var(--radius);
      float: left;

      @include respond-above(lg) {
        background: var(--bg-hover);
      }

      @include respond-above(sm) {
        width: 50%;
      }

      &:hover {
        background: var(--bg-hover);

        img {
          filter: grayscale(0);
        }
      }

      &.big {
        width: 50%;

        @include respond-above(sm) {
          width: 100%;
        }
      }

      img {
        width: 100%;
        filter: grayscale(1);
        display: block;

        @include respond-above(lg) {
          filter: grayscale(0);
        }
      }
    }
  }
}

.contacts {
  padding: get-vw(160px) 0 0 0;
  position: relative;
  background: #151515;

  @include respond-above(lg) {
    padding: get-vw-lg(170px) 0 0 0;
  }

  @include respond-above(sm) {
    padding: get-vw-sm(124px) 0 0 0;
  }

  .lines {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;

    .line {
      background-color: #ffffff;
      position: absolute;
      opacity: 0.2;

      &:first-child {
        width: 1px;
        left: 50%;
        top: 0;
        bottom: 0;
      }

      &:nth-child(2) {
        top: get-vw(250px);
        left: 0;
        right: 0;
        height: 1px;

        @include respond-above(lg) {
          top: get-vw-lg(220px);
        }

        @include respond-above(sm) {
          top: get-vw-sm(172px);
        }
      }
    }
  }

  .title {
    padding: 0 0 get-vw(50px) get-vw(30px);
    position: relative;
    z-index: 2;

    @include respond-above(lg) {
      padding: 0 0 get-vw-lg(28px) get-vw-lg(15px);
    }

    @include respond-above(sm) {
      padding: 0 0 get-vw-sm(28px) get-vw-sm(15px);
    }

    h1 {
      opacity: 0.2;
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(64px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.16;
      letter-spacing: normal;
      color: #ffffff;
      text-transform: uppercase;

      @include respond-above(lg) {
        font-size: get-vw-lg(34px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(34px);
      }
    }
  }

  form.contacts-form {
    margin: 0 get-vw(6px);
    border-radius: get-vw(30px);
    background-color: #2a2929;
    padding: get-vw(28px) get-vw(24px) get-vw(19px) get-vw(24px);
    position: relative;
    z-index: 2;

    @include respond-above(lg) {
      margin: 0 get-vw-lg(5px);
      border-radius: get-vw-lg(20px);
      padding: get-vw-lg(30px) get-vw-lg(15px);
    }

    @include respond-above(sm) {
      margin: 0;
      border-radius: get-vw-sm(20px);
      padding: get-vw-sm(30px) get-vw-sm(15px);
    }

    &.error {
      .error-row {
        opacity: 1;
      }

      .error-row-mob {
        opacity: 1;
        height: auto;
      }
    }

    .form-success {
      display: none;
      padding-bottom: get-vw(25px);

      @include respond-above(lg) {
        padding-bottom: 0;
      }

      .svg-wrap {
        margin-bottom: get-vw(90px);

        @include respond-above(lg) {
          margin-bottom: get-vw-lg(90px);
        }

        @include respond-above(sm) {
          margin-bottom: get-vw-sm(90px);
        }
      }

      svg {
        width: get-vw(140px);
        height: get-vw(140px);

        @include respond-above(lg) {
          width: get-vw-lg(110px);
          height: get-vw-lg(110px);
        }

        @include respond-above(sm) {
          width: get-vw-sm(110px);
          height: get-vw-sm(110px);
        }
      }

      .text-button {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-above(lg) {
          display: block;
        }

        .text {
          h4 {
            font-family: 'Basis Grotesque Pro', sans-serif;
            font-size: get-vw(48px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: get-vw(1px);

            @include respond-above(lg) {
              font-size: get-vw-lg(28px);
              margin-bottom: get-vw-lg(13px);
            }

            @include respond-above(sm) {
              font-size: get-vw-sm(28px);
              margin-bottom: get-vw-sm(13px);
            }
          }

          p {
            font-family: NotoMono, sans-serif;
            font-size: get-vw(14px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            color: #ffffff;

            @include respond-above(lg) {
              font-size: get-vw-lg(13px);
            }

            @include respond-above(sm) {
              font-size: get-vw-sm(13px);
              max-width: get-vw-sm(266px);
            }
          }
        }

        .button-wrap {
          margin-left: get-vw(20px);

          @include respond-above(lg) {
            margin-left: 0;
            margin-top: get-vw-lg(45px);
          }

          @include respond-above(sm) {
            margin-left: 0;
            margin-top: get-vw-sm(45px);
          }

          button {
            width: get-vw(130px);
            height: get-vw(40px);
            -webkit-appearance: none;
            border: none;
            outline: none;
            border-radius: get-vw(20px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: NotoMono, sans-serif;
            font-size: get-vw(16px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #000000;
            cursor: pointer;

            @include respond-above(lg) {
              width: get-vw-lg(341px);
              height: get-vw-lg(40px);
              border-radius: get-vw-lg(20px);
              font-size: get-vw-lg(18px);
            }

            @include respond-above(sm) {
              width: 100%;
              height: get-vw-sm(40px);
              border-radius: get-vw-sm(20px);
              font-size: get-vw-sm(18px);
            }

            &:hover {
              background-color: #ff274f;
              color: #ffffff;
            }
          }
        }
      }
    }

    &.success {
      .form-inner {
        display: none;
      }

      .form-success {
        display: block;
      }
    }

    textarea {
      -webkit-appearance: none;
      width: 100%;
      border: none;
      outline: none;
      border-radius: 0;
      resize: none;
      min-height: get-vw(260px);
      background: transparent;
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(48px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #ffffff;
      caret-color: #ff274f;
      margin-bottom: get-vw(40px);
      box-sizing: border-box;

      @include respond-above(lg) {
        min-height: get-vw-lg(150px);
        font-size: get-vw-lg(26px);
        margin-bottom: get-vw-lg(30px);
      }

      @include respond-above(sm) {
        min-height: get-vw-sm(150px);
        font-size: get-vw-sm(26px);
        margin-bottom: get-vw-sm(30px);
      }

      &::placeholder {
        color: rgba(255,255,255,0.1);
      }

      &.error {
        color: #ff274f;

        &::placeholder {
          color: #ff274f;
        }
      }

      &.two-rows {
        min-height: get-vw(220px);

        @include respond-above(lg) {
          min-height: get-vw-lg(150px);
        }

        @include respond-above(sm) {
          min-height: get-vw-sm(150px);
        }
      }
    }

    .inputs-row {
      display: flex;
      justify-content: space-between;

      &.margin-bottom {
        margin-bottom: get-vw(12px);

        @include respond-above(lg) {
          margin-bottom: 0;
        }
      }

      @include respond-above(lg) {
        display: block;
      }

      .inputs {
        display: flex;
        flex-wrap: wrap;

        .input-wrap {
          margin-right: get-vw(25px);

          @include respond-above(lg) {
            margin-right: 0;
            width: 100%;
            margin-bottom: get-vw-lg(15px);
          }

          @include respond-above(sm) {
            margin-bottom: get-vw-sm(15px);
          }

          &:last-child {
            margin-right: 0;
          }

          input {
            -webkit-appearance: none;
            outline: none;
            border-radius: get-vw(20px);
            background-color: rgba(255,255,255,0.1);
            width: get-vw(320px);
            height: get-vw(40px);
            padding: 0 get-vw(27px);
            box-sizing: border-box;
            font-family: NotoMono, sans-serif;
            font-size: get-vw(14px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            color: #ffffff;
            caret-color: #ff274f;
            transition: .2s ease-in-out background, .2s ease-in-out color;
            border: none;

            @include respond-above(lg) {
              border-radius: get-vw-lg(20px);
              width: 100%;
              height: get-vw-lg(40px);
              padding: 0 get-vw-lg(26px);
              font-size: get-vw-lg(16px);
            }

            @include respond-above(sm) {
              border-radius: get-vw-sm(20px);
              height: get-vw-sm(40px);
              padding: 0 get-vw-sm(26px);
              font-size: get-vw-sm(16px);
            }

            &:hover {
              background-color: rgba(255,255,255,0.18);
            }

            &::placeholder {
              color: rgba(255,255,255,0.4);
            }

            &.error {
              color: #ff274f;
              background: rgba(255, 39, 79, 0.3);

              &::placeholder {
                color: #ff274f;
              }
            }
          }

          .select2-container {
            width: get-vw(320px) !important;
            height: get-vw(40px);

            @include respond-above(lg) {
              width: 100% !important;
              height: get-vw-lg(40px);
              margin-top: 0;
            }

            @include respond-above(sm) {
              height: get-vw-sm(40px);
            }

            &:hover {
              .select2-selection--single {
                background-color: rgba(255,255,255,0.18);
              }
            }
          }

          .select2-container--default .select2-selection--single {
            border: none;
            outline: none;
            width: get-vw(320px);
            height: get-vw(40px);
            -webkit-appearance: none;
            border-radius: get-vw(20px);
            background-color: rgba(255,255,255,0.1);
            transition: .2s ease-in-out background;
            position: relative;

            @include respond-above(lg) {
              width: 100%;
              border-radius: get-vw-lg(20px);
              height: get-vw-lg(40px);
            }

            @include respond-above(sm) {
              height: get-vw-sm(40px);
              border-radius: get-vw-sm(20px);
            }
          }

          &.selected-select {
            .select2-container--default .select2-selection--single .select2-selection__rendered {
              color: #ffffff;
            }
          }

          &.error-select {
            .select2-container--default .select2-selection--single {
              background: rgba(255, 39, 79, 0.3);
            }

            .select2-container--default .select2-selection--single .select2-selection__rendered {
              color: #ff274f;
            }

            .select2-container--default .select2-selection--single .select2-selection__arrow b {
              border-color: #ff274f transparent transparent;
            }

            .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
              border-color: transparent transparent #ff274f;
            }
          }

          .select2-container--default .select2-selection--single .select2-selection__rendered {
            padding: 0 get-vw(27px);
            font-family: NotoMono, sans-serif;
            font-size: get-vw(14px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: get-vw(40px);
            letter-spacing: normal;
            color: rgba(255,255,255,0.4);

            @include respond-above(lg) {
              padding: 0 get-vw-lg(26px);
              font-size: get-vw-lg(16px);
              line-height: get-vw-lg(40px);
            }

            @include respond-above(sm) {
              padding: 0 get-vw-sm(26px);
              font-size: get-vw-sm(16px);
              line-height: get-vw-sm(40px);
            }
          }

          .select2-container--default .select2-selection--single .select2-selection__arrow {
            height: 100%;
            top: 0;
            right: get-vw(27px);
            bottom: 0;
            width: auto;

            @include respond-above(lg) {
              right: get-vw-lg(26px);
            }

            @include respond-above(sm) {
              right: get-vw-sm(26px);
            }
          }

          .select2-container--default .select2-selection--single .select2-selection__arrow b {
            border-color: #ffffff transparent transparent;
          }

          .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
            border-color: transparent transparent #ffffff;
          }
        }
      }

      .submit {
        @include respond-above(lg) {
          float: none;
          margin-top: get-vw-lg(15px);
        }

        @include respond-above(sm) {
          margin-top: get-vw-sm(15px);
        }

        button {
          width: get-vw(130px);
          height: get-vw(40px);
          -webkit-appearance: none;
          border: none;
          outline: none;
          border-radius: get-vw(20px);
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: NotoMono, sans-serif;
          font-size: get-vw(16px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #000000;
          cursor: pointer;
          background: #ffffff;

          @include respond-above(lg) {
            width: 100%;
            height: get-vw-lg(40px);
            border-radius: get-vw-lg(20px);
            font-size: get-vw-lg(18px);
          }

          @include respond-above(sm) {
            height: get-vw-sm(40px);
            border-radius: get-vw-sm(20px);
            font-size: get-vw-sm(18px);
          }

          &:hover {
            background-color: #ff274f;
            color: #ffffff;
          }
        }
      }
    }

    .error-row {
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ff274f;
      margin-top: get-vw(13px);
      opacity: 0;
      transition: .2s ease-in-out opacity;
      pointer-events: none;

      @include respond-above(lg) {
        display: none;
      }
    }

    .error-row-mob {
      display: none;
      font-family: NotoMono, sans-serif;
      font-size: get-vw-lg(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ff274f;
      margin-top: get-vw-lg(-6px);
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: .2s ease-in-out opacity;
      pointer-events: none;
      text-align: center;
      padding: 0 get-vw-lg(30px);

      @include respond-above(lg) {
        display: block;
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(13px);
        padding: 0 get-vw-sm(30px);
        margin-top: get-vw-sm(-6px);
      }
    }
  }
}

.select2-dropdown {
  margin-top: get-vw(2px);
  border: none;
  border-radius: get-vw(20px) !important;
  background-color: #ffffff;
  overflow: hidden;
  width: get-vw(320px) !important;

  @include respond-above(lg) {
    border-radius: get-vw-lg(20px) !important;
    margin-top: get-vw-lg(2px);
    width: get-vw-lg(341px) !important;
  }

  @include respond-above(sm) {
    border-radius: get-vw-sm(20px) !important;
    margin-top: get-vw-sm(2px);
    width: get-vw-sm(345px) !important;
  }
}

.select2-dropdown.select2-dropdown--above {
  margin-top: get-vw(-2px);

  @include respond-above(lg) {
    margin-top: get-vw-lg(-2px);
  }

  @include respond-above(sm) {
    margin-top: get-vw-sm(-2px);
  }
}

.select2-container--classic .select2-results>.select2-results__options, .select2-container--default .select2-results>.select2-results__options {
  max-height: get-vw(250px);

  @include respond-above(lg) {
    max-height: get-vw-lg(250px);
  }

  @include respond-above(sm) {
    max-height: get-vw-sm(250px);
  }
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none;
}

.select2-results__option {
  font-family: NotoMono, sans-serif;
  font-size: get-vw(14px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: normal;
  color: rgba(0,0,0,0.7);
  padding: 0 get-vw(27px);
  border-bottom: 1px solid #d8d8d8;
  transition: .2s ease-in-out background, .2s ease-in-out color;

  @include respond-above(lg) {
    font-size: get-vw-lg(14px);
    padding: 0 get-vw-lg(26px);
  }

  @include respond-above(sm) {
    font-size: get-vw-sm(14px);
    padding: 0 get-vw-sm(26px);
  }

  &:last-child {
    border-bottom: none;
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(0,0,0,0.08)!important;
  color: #000000!important;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #ffffff;
  color: rgba(0,0,0,0.7);
}

.page-404-wrap {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);

  .text-wrap {
    padding: get-vw(6px) get-vw(6px) 0 get-vw(6px);

    @include respond-above(lg) {
      padding: get-vw-lg(5px) get-vw-lg(5px) 0 get-vw-lg(5px);
    }

    @include respond-above(sm) {
      padding: get-vw-sm(5px) get-vw-sm(5px) 0 get-vw-sm(5px);

      @media (orientation: landscape) {
        min-height: get-vw-sm(300px);
      }
    }
  }

  .text {
    border-radius: get-vw(30px);
    background-color: #2a2929;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-above(lg) {
      border-radius: get-vw-lg(20px);
    }

    @include respond-above(sm) {
      border-radius: get-vw-sm(20px);
    }

    h1 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(64px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ff274f;
      text-align: center;
      margin-bottom: get-vw(40px);

      @include respond-above(lg) {
        font-size: get-vw-lg(34px);
        margin-bottom: get-vw-lg(28px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(34px);
        margin-bottom: get-vw-sm(28px);
      }
    }

    .text-lines {
      position: relative;

      .lines {
        @include respond-above(lg) {
          display: none;
        }

        .line {
          position: absolute;
          width: 1px;
          top: get-vw(5px);
          height: get-vw(49px);
          background-color: #ff274f;

          &:first-child {
            left: get-vw(234px);
          }

          &:last-child {
            right: get-vw(234px);
          }
        }
      }
    }

    p {
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #ff274f;
      max-width: get-vw(600px);
      margin: 0 auto 10% auto;

      @include respond-above(lg) {
        font-size: get-vw-lg(13px);
        max-width: get-vw-lg(445px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(13px);
        max-width: get-vw-sm(345px);
      }
    }
  }

  .counter {
    height: get-vw(142px);
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-above(lg) {
      height: get-vw-lg(147px);
    }

    @include respond-above(sm) {
      height: get-vw-sm(147px);
      display: block;
      padding: 0 get-vw-sm(15px);
    }

    .time {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(57px);
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: 4.45px;
      text-align: center;
      color: #ff274f;
      width: get-vw(120px);

      @include respond-above(lg) {
        font-size: get-vw-lg(40px);
        width: get-vw-lg(120px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(40px);
        width: 100%;
        line-height: 1.85;
        letter-spacing: 3.13px;
      }
    }

    .button-wrap {
      position: absolute;
      z-index: 1;
      right: get-vw(40px);

      @include respond-above(lg) {
        right: get-vw-lg(30px);
      }

      @include respond-above(sm) {
        position: static;
      }

      a {
        text-decoration: none;
        font-family: NotoMono, sans-serif;
        font-size: get-vw(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #000000;
        width: get-vw(160px);
        height: get-vw(40px);
        border-radius: get-vw(20px);
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s ease-in-out color, .2s ease-in-out background;

        @include respond-above(lg) {
          width: get-vw-lg(160px);
          height: get-vw-lg(40px);
          border-radius: get-vw-lg(20px);
          font-size: get-vw-lg(18px);
        }

        @include respond-above(sm) {
          width: 100%;
          height: get-vw-sm(40px);
          border-radius: get-vw-sm(20px);
          font-size: get-vw-sm(18px);
        }

        &:hover {
          background-color: #ff274f;
          color: #ffffff;
        }
      }
    }
  }
}

.projects-header {
  height: get-vw(290px);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background: #151515;

  @include respond-above(lg) {
    height: get-vw-lg(240px);
  }

  @include respond-above(sm) {
    height: get-vw-sm(191px);
  }

  .lines {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;

    .line {
      background-color: #ffffff;
      position: absolute;
      opacity: 0.2;

      &:first-child {
        width: 1px;
        left: 50%;
        top: 0;
        bottom: 0;

        @include respond-above(lg) {
          height: 90vh;
          bottom: auto;
        }
      }

      &:nth-child(2) {
        top: get-vw(250px);
        left: 0;
        right: 0;
        height: 1px;

        @include respond-above(lg) {
          top: get-vw-lg(220px);
        }

        @include respond-above(sm) {
          top: get-vw-sm(172px);
        }
      }
    }
  }

  h1 {
    opacity: 0.2;
    font-family: 'Basis Grotesque Pro', sans-serif;
    font-size: get-vw(64px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
    position: absolute;
    left: get-vw(30px);
    top: get-vw(160px);

    @include respond-above(lg) {
      font-size: get-vw-lg(34px);
      left: get-vw-lg(15px);
      top: get-vw-lg(170px);
    }

    @include respond-above(sm) {
      font-size: get-vw-sm(34px);
      left: get-vw-sm(15px);
      top: get-vw-sm(125px);
    }
  }
}

.projects-page {
  padding: get-vw(50px) 0 0 0;

  @include respond-above(lg) {
    padding: get-vw-lg(50px) 0 0 0;
  }

  @include respond-above(sm) {
    padding: get-vw-sm(34px) 0 0 0;
  }

  .projects-grid-wrap {
    padding-bottom: get-vw(100px);

    @include respond-above(lg) {
      padding-bottom: get-vw-lg(100px);
    }

    @include respond-above(sm) {
      padding-bottom: get-vw-sm(100px);
    }
  }

  .projects-filter {
    margin-bottom: get-vw(112px);
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 4;

    @include respond-above(lg) {
      margin-bottom: get-vw-lg(93px);
    }

    @include respond-above(sm) {
      margin-bottom: get-vw-sm(73px);
      overflow: scroll;
      justify-content: flex-start;
      padding-bottom: get-vw-sm(20px);
    }

    ul {
      display: flex;
      justify-content: center;
      border-radius: get-vw(15px);
      background: rgba(255,255,255,0.1);
      height: get-vw(33px);
      align-items: center;
      position: relative;

      @include respond-above(lg) {
        border-radius: get-vw-lg(20px);
        height: get-vw-lg(40px);
      }

      @include respond-above(sm) {
        border-radius: get-vw-sm(20px);
        height: get-vw-sm(40px);
        margin: 0 get-vw-sm(30px);
      }

      @media screen and (min-width: 1110px) {
        &:hover {
          a {
            &.active {
              color: rgba(255, 255, 255, 0.4);

              &:hover {
                color: #000000;
              }
            }
          }
        }
      }

      li {
        &.hover {
          height: get-vw(27px);
          border-radius: get-vw(14px);
          background: #fff;
          position: absolute;
          transition: .2s ease-in-out left, .2s ease-in-out width;

          @include respond-above(lg) {
            display: none;
          }
        }

        a {
          display: flex;
          height: get-vw(27px);
          padding: 0 get-vw(16px);
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-family: NotoMono,sans-serif;
          font-size: get-vw(14px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: normal;
          color: rgba(255,255,255,0.4);
          transition: .2s ease-in-out color, .2s ease-in-out background;
          border-radius: get-vw(14px);
          position: relative;
          z-index: 1;
          white-space: nowrap;

          @include respond-above(lg) {
            height: get-vw-lg(34px);
            padding: 0 get-vw-lg(16px);
            font-size: get-vw-lg(14px);
            border-radius: get-vw-lg(17px);
          }

          @include respond-above(sm) {
            height: get-vw-sm(34px);
            padding: 0 get-vw-sm(16px);
            font-size: get-vw-sm(14px);
            border-radius: get-vw-sm(17px);
          }

          &.active {
            color: #000000;

            @include respond-above(lg) {
              background: #fff;
            }
          }

          @media screen and (min-width: 1110px) {
            &:hover {
              color: #000000;

              @include respond-above(lg) {
                background: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.projectin {
  margin: get-vw(70px) get-vw(6px) 0 get-vw(6px);
  border-radius: get-vw(30px);
  background-color: #ffffff;
  padding-bottom: get-vw(52px);
  box-sizing: border-box;

  @include respond-above(lg) {
    margin: get-vw-lg(50px) get-vw-lg(5px) 0 get-vw-lg(5px);
    border-radius: get-vw-lg(20px);
    padding-bottom: get-vw-lg(50px);
  }

  @include respond-above(sm) {
    margin: get-vw-sm(50px) get-vw-sm(5px) 0 get-vw-sm(5px);
    border-radius: get-vw-sm(20px);
    padding-bottom: get-vw-sm(50px);
  }

  &.scaled {
    transform: scale(0.95);
    margin-bottom: 0;
    transform-origin: bottom;
    border-radius: get-vw(30px) get-vw(30px) 0 0;
    cursor: pointer;
    transition: .2s ease-in-out background;

    @include respond-above(lg) {
      border-radius: get-vw-lg(20px) get-vw-lg(20px) 0 0;
    }

    @include respond-above(sm) {
      border-radius: get-vw-sm(20px) get-vw-sm(20px) 0 0;
    }

    a.close-project {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      background: #DBDBDB;
    }
  }

  .projectin-header {
    padding: get-vw(33px) get-vw(24px) get-vw(15px) get-vw(24px);
    position: relative;

    @include respond-above(lg) {
      padding: get-vw-lg(40px) get-vw-lg(15px) get-vw-lg(22px) get-vw-lg(15px);
    }

    @include respond-above(sm) {
      padding: get-vw-sm(40px) get-vw-sm(15px) get-vw-sm(22px) get-vw-sm(15px);
    }

    .code-close {
      position: absolute;
      z-index: 2;
      right: get-vw(25px);
      top: get-vw(25px);
      display: flex;
      align-items: center;

      &.fixed {
        position: fixed;
        margin-right: get-vw(6px);
        z-index: 100;

        @include respond-above(lg) {
          margin-right: get-vw-lg(5px);
        }

        @include respond-above(sm) {
          margin-right: get-vw-sm(5px);
        }

        .code {
          opacity: 0;
        }
      }

      @include respond-above(lg) {
        right: get-vw-lg(10px);
        top: get-vw-lg(10px);
      }

      @include respond-above(sm) {
        right: get-vw-sm(10px);
        top: get-vw-sm(10px);
        flex-direction: column;
        justify-content: center;
      }

      .code {
        font-family: NotoMono, sans-serif;
        font-size: get-vw(34px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ff274f;
        margin-right: get-vw(30px);
        transition: .2s ease-in-out opacity;

        @include respond-above(lg) {
          font-size: get-vw-lg(18px);
          margin-right: get-vw-lg(20px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(18px);
          margin-right: 0;
          order: 1;
          margin-top: get-vw-sm(3px);
        }
      }

      a.close-project {
        width: get-vw(58px);
        height: get-vw(58px);
        display: flex;
        background-color: #000000;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: .2s ease-in-out background;

        @include respond-above(lg) {
          width: get-vw-lg(32px);
          height: get-vw-lg(32px);
        }

        @include respond-above(sm) {
          width: get-vw-sm(32px);
          height: get-vw-sm(32px);
          order: 0;
        }

        &:hover {
          background-color: #ff274f;

          svg {
            height: get-vw(20px);
            width: get-vw(20px);

            @include respond-above(lg) {
              height: get-vw-lg(14px);
              width: get-vw-lg(14px);
            }

            @include respond-above(sm) {
              height: get-vw-sm(14px);
              width: get-vw-sm(14px);
            }
          }
        }

        svg {
          height: get-vw(22px);
          width: get-vw(22px);
          transition: .2s ease-in-out all;

          @include respond-above(lg) {
            height: get-vw-lg(14px);
            width: get-vw-lg(14px);
          }

          @include respond-above(sm) {
            height: get-vw-sm(14px);
            width: get-vw-sm(14px);
          }
        }
      }
    }

    .title-tags {
      max-width: 85%;

      @include respond-above(sm) {
        max-width: 100%;
      }

      h1 {
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(56px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #000000;

        @include respond-above(lg) {
          font-size: get-vw-lg(28px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(28px);
          max-width: 85%;
        }
      }

      .tags-category {
        margin-top: get-vw(33px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        @include respond-above(lg) {
          margin-top: get-vw-lg(10px);
        }

        @include respond-above(sm) {
          margin-top: get-vw-sm(10px);
        }

        .category {
          margin-right: get-vw(30px);
          border: solid 1px #000000;
          border-radius: get-vw(14px);
          padding: get-vw(2px) get-vw(24px) get-vw(3px) get-vw(24px);
          font-family: NotoMono, sans-serif;
          font-size: get-vw(14px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: normal;
          color: #000000;
          text-decoration: none;
          transition: .2s ease-in-out background, .2s ease-in-out border;
          display: block;

          @include respond-above(lg) {
            margin-right: get-vw-lg(20px);
            border-radius: get-vw-lg(14px);
            padding: get-vw-lg(2px) get-vw-lg(16px) get-vw-lg(3px) get-vw-lg(16px);
            font-size: get-vw-lg(14px);
          }

          @include respond-above(sm) {
            margin-right: get-vw-sm(20px);
            border-radius: get-vw-sm(14px);
            padding: get-vw-sm(2px) get-vw-sm(16px) get-vw-sm(3px) get-vw-sm(16px);
            font-size: get-vw-sm(14px);
          }

          &:hover {
            background: rgba(0,0,0,0.2);
            border-color: transparent;
          }
        }

        .tags {
          display: flex;
          flex-wrap: wrap;

          .tag {
            font-family: NotoMono, sans-serif;
            font-size: get-vw(13px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            margin-right: get-vw(22px);
            position: relative;
            transition: .2s ease-in-out color;

            @include respond-above(lg) {
              font-size: get-vw-lg(13px);
              margin-right: get-vw-lg(22px);
            }

            @include respond-above(sm) {
              font-size: get-vw-sm(13px);
              margin-right: get-vw-sm(22px);
            }

            &:after {
              content: '/';
              position: absolute;
              right: get-vw(-15px);
              top: 0;
              bottom: 0;

              @include respond-above(lg) {
                right: get-vw-lg(-15px);
              }

              @include respond-above(sm) {
                right: get-vw-sm(-15px);
              }
            }

            &:last-child {
              margin-right: 0;

              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .projectin-meta {
    margin-bottom: get-vw(40px);

    @include respond-above(lg) {
      margin-bottom: get-vw-lg(40px);
    }

    @include respond-above(sm) {
      margin-bottom: get-vw-sm(40px);
    }

    .projectin-meta-item {
      border-top: 1px solid rgba(0,0,0,0.3);
      padding: get-vw(10px) get-vw(114px) get-vw(14px) get-vw(750px);
      display: flex;

      @include respond-above(lg) {
        padding: get-vw-lg(15px);
      }

      @include respond-above(sm) {
        display: block;
        padding: get-vw-sm(8px) get-vw-sm(15px) get-vw-sm(15px) get-vw-sm(15px);
      }

      .name {
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: #000000;
        text-transform: uppercase;
        width: get-vw(110px);
        flex-shrink: 0;
        margin-right: get-vw(10px);

        @include respond-above(lg) {
          font-size: get-vw-lg(16px);
          width: 30%;
          margin-right: get-vw-lg(10px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(16px);
          width: 100%;
          margin-right: 0;
        }
      }

      .value {
        font-family: NotoMono, sans-serif;
        font-size: get-vw(13px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: #000000;

        @include respond-above(lg) {
          font-size: get-vw-lg(13px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(13px);
          margin-top: get-vw-sm(8px);
        }

        img {
          max-width: 100%;
          margin-top: get-vw(4px);

          @include respond-above(lg) {
            margin-top: get-vw-lg(4px);
          }

          @include respond-above(sm) {
            margin-top: 0;
          }
        }
      }
    }
  }

  .projectin-img-full-width {
    img {
      width: 100%;
      display: block;
    }
  }

  .projectin-text {
    margin: get-vw(79px) 0 get-vw(150px) 0;

    @include respond-above(lg) {
      margin: get-vw-lg(70px) 0 get-vw-lg(130px) 0;
    }

    @include respond-above(sm) {
      margin: get-vw-sm(70px) 0 get-vw-sm(130px) 0;
    }

    &.full-width {
      padding: 0 get-vw(24px);

      @include respond-above(lg) {
        padding: 0 get-vw-lg(15px);
      }

      @include respond-above(sm) {
        padding: 0 get-vw-sm(15px);
      }
    }

    &.right {
      padding: 0 get-vw(24px) 0 get-vw(750px);

      @include respond-above(lg) {
        padding: 0 get-vw-lg(15px);
      }

      @include respond-above(sm) {
        padding: 0 get-vw-sm(15px);
      }
    }

    h3 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(30px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #000000;

      @include respond-above(lg) {
        font-size: get-vw-lg(20px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(20px);
      }
    }
  }

  .projectin-text-big {
    margin: get-vw(150px) 0;
    text-align: center;
    padding: 0 get-vw(234px);

    @include respond-above(lg) {
      margin: get-vw-lg(130px) 0;
      padding: 0 get-vw-lg(15px);
    }

    @include respond-above(sm) {
      margin: get-vw-sm(130px) 0;
      padding: 0 get-vw-sm(15px);
    }

    h2 {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: get-vw(48px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #ff274f;

      @include respond-above(lg) {
        font-size: get-vw-lg(24px);
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(24px);
      }
    }
  }

  .projectin-3-img {
    .text {
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #151515;
      position: absolute;
      left: get-vw(25px);
      top: get-vw(20px);
      z-index: 2;
      max-width: get-vw(215px);

      @include respond-above(lg) {
        font-size: get-vw-lg(13px);
        left: get-vw-lg(15px);
        right: get-vw-lg(15px);
        top: get-vw-lg(12px);
        max-width: none;
      }

      @include respond-above(sm) {
        font-size: get-vw-sm(13px);
        left: get-vw-sm(15px);
        top: get-vw-sm(12px);
        right: get-vw-sm(15px);
      }
    }

    .img-1 {
      width: 50%;
      float: left;
      position: relative;

      @include respond-above(sm) {
        width: 100%;
        float: none;
      }

      img {
        width: 100%;
        display: block;
        position: relative;
        z-index: 1;
      }
    }

    .img-2 {
      width: 50%;
      float: right;
      position: relative;

      @include respond-above(sm) {
        width: 100%;
        float: none;
        min-height: get-vw-sm(204px);
      }

      img {
        width: 100%;
        display: block;
        position: relative;
        z-index: 1;
      }
    }

    .img-3 {
      width: 50%;
      position: relative;
      float: right;

      @include respond-above(sm) {
        width: 100%;
        float: none;
        min-height: get-vw-sm(204px);
      }

      img {
        width: 100%;
        display: block;
        position: relative;
        z-index: 1;
      }
    }
  }

  .projectin-steps {
    margin: get-vw(80px) 0;
    padding: 0 get-vw(24px);

    @include respond-above(lg) {
      margin: get-vw-lg(120px) 0;
      padding: 0 get-vw-lg(15px);
    }

    @include respond-above(sm) {
      margin: get-vw-sm(120px) 0;
      padding: 0 get-vw-sm(15px);
    }

    .scheme {
      &:after {
        display: none;
      }

      .scheme-item {
        padding-left: 0;
      }
    }

    .scheme .scheme-item:nth-child(1):after {
      display: none;
    }

    .scheme .scheme-item .name {
      margin-bottom: get-vw(13px);
      margin-top: get-vw(11px);
      line-height: 1.33;
      color: #000000;
      text-transform: none;
      font-size: get-vw(30px);

      @include respond-above(lg) {
        background: none;
        margin-bottom: get-vw-lg(6px);
        margin-top: get-vw-lg(11px);
        font-size: get-vw-lg(24px);

        &:after {
          top: get-vw-lg(10px);
        }
      }

      @include respond-above(sm) {
        margin-bottom: get-vw-sm(6px);
        margin-top: get-vw-sm(11px);
        font-size: get-vw-sm(24px);

        &:after {
          top: get-vw-sm(10px);
        }
      }

      &:after {
        background: #000;
      }
    }

    .scheme .scheme-item .desc {
      color: #000000;
    }
  }

  .projectin-cta {
    margin: get-vw(200px) 0 get-vw(130px) 0;
    text-align: center;

    @include respond-above(lg) {
      margin: get-vw-lg(200px) 0 get-vw-lg(130px) 0;
    }

    @include respond-above(sm) {
      margin: get-vw-sm(200px) 0 get-vw-sm(130px) 0;
    }

    p {
      padding: 0 get-vw(474px);
      font-family: NotoMono, sans-serif;
      font-size: get-vw(13px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #000000;

      @include respond-above(lg) {
        padding: 0 get-vw-lg(100px);
        font-size: get-vw-lg(13px);
      }

      @include respond-above(sm) {
        padding: 0 get-vw-sm(15px);
        font-size: get-vw-sm(13px);
      }
    }

    .link {
      margin-top: get-vw(28px);

      @include respond-above(lg) {
        margin-top: get-vw-lg(40px);
      }

      @include respond-above(sm) {
        margin-top: get-vw-sm(40px);
      }

      a {
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(30px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.87;
        letter-spacing: normal;
        text-align: center;
        color: #ff274f;
        text-decoration: underline;

        @include respond-above(lg) {
          font-size: get-vw-lg(20px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(20px);
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .projectin-total {
    margin: get-vw(100px) 0 0 0;
    padding: 0 get-vw(24px);

    @include respond-above(lg) {
      margin: get-vw-lg(100px) 0 0 0;
      padding: 0 get-vw-lg(15px);
    }

    @include respond-above(sm) {
      margin: get-vw-sm(100px) 0 0 0;
      padding: 0 get-vw-sm(15px);
    }

    .projectin-total-item {
      border-top: 1px solid rgba(0,0,0,0.3);
      display: flex;
      align-items: center;

      @include respond-above(sm) {
        display: block;
        padding: get-vw-sm(8px) 0 get-vw-sm(15px) 0;
        position: relative;
      }

      &:last-child {
        border-bottom: 1px solid rgba(0,0,0,0.3);
      }

      .name {
        width: get-vw(726px);
        flex-shrink: 0;
        font-family: 'Basis Grotesque Pro', sans-serif;
        font-size: get-vw(16px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: normal;
        color: #000000;
        text-transform: uppercase;

        @include respond-above(lg) {
          width: 55%;
          font-size: get-vw-lg(16px);
        }

        @include respond-above(sm) {
          width: 100%;
          font-size: get-vw-sm(16px);
          line-height: normal;
          margin-bottom: get-vw-sm(8px);
        }

        img {
          display: block;
          height: get-vw(18px);
          margin: get-vw(11px) 0;

          @include respond-above(lg) {
            height: get-vw-lg(18px);
            margin: get-vw-lg(11px) 0;
          }

          @include respond-above(sm) {
            height: get-vw-sm(18px);
            margin: get-vw-sm(4px) 0 0 0;
          }
        }
      }

      .value {
        width: get-vw(444px);
        flex-shrink: 0;
        font-family: NotoMono, sans-serif;
        font-size: get-vw(13px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: #000000;
        display: flex;
        align-items: center;

        @include respond-above(lg) {
          width: 35%;
          font-size: get-vw-lg(13px);
        }

        @include respond-above(sm) {
          font-size: get-vw-sm(13px);
          width: 60%;
        }

        .stars {
          display: flex;
          margin-left: get-vw(10px);

          @include respond-above(lg) {
            margin-left: get-vw-lg(10px);
          }

          @include respond-above(sm) {
            margin-left: get-vw-sm(10px);
          }

          .star {
            margin-right: get-vw(1px);

            @include respond-above(lg) {
              margin-right: get-vw-lg(1px);
            }

            @include respond-above(sm) {
              margin-right: get-vw-sm(1px);
            }

            svg {
              width: get-vw(12px);
              height: get-vw(12px);

              @include respond-above(lg) {
                width: get-vw-lg(12px);
                height: get-vw-lg(12px);
              }

              @include respond-above(sm) {
                width: get-vw-sm(12px);
                height: get-vw-sm(12px);
              }
            }
          }
        }
      }

      .link {
        text-align: right;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;

        @include respond-above(sm) {
          width: 40%;
          position: absolute;
          right: 0;
          bottom: get-vw-sm(15px);
        }

        a {
          text-decoration: underline;
          font-family: NotoMono, sans-serif;
          font-size: get-vw(13px);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.54;
          letter-spacing: normal;
          text-align: right;
          color: #ff274f;
          position: relative;
          transition: .2s ease-in-out color;
          display: flex;
          align-items: center;

          @include respond-above(lg) {
            font-size: get-vw-lg(13px);
          }

          @include respond-above(sm) {
            font-size: get-vw-sm(13px);
          }

          span {
            position: relative;
            z-index: 1;
          }

          &:hover {
            text-decoration: none;
            color: #fff;

            &:after {
              opacity: 1;
            }

            .arrow {
              transform: translateX(0);
              opacity: 1;
            }
          }

          &:after {
            content: '';
            position: absolute;
            left: get-vw(-10px);
            right: get-vw(-10px);
            top: 0;
            bottom: 0;
            background-color: #ff274f;
            opacity: 0;
            transition: .2s ease-in-out opacity;
            z-index: 0;

            @include respond-above(lg) {
              display: none;
            }
          }

          .arrow {
            position: absolute;
            left: get-vw(-44px);
            display: flex;
            align-items: center;
            transition: .2s ease-in-out transform, .2s ease-in-out opacity;
            transform: translateX(-30px);
            opacity: 0;

            @include respond-above(lg) {
              display: none;
            }

            svg {
              width: get-vw(16px);
              height: get-vw(18px);
            }
          }
        }
      }
    }
  }
}

.clearfix {
  clear: both;
}

b, strong {
  font-weight: bold!important;
}

i, em {
  font-style: italic!important;
}
