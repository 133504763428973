// mixins

$breakpoints: (
        xs: 350px,
        sm: 767px,
        lg: 1110px,
);

// Respond above.
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @if($breakpoint == sm) {
      @media screen and (max-width: $breakpoint-value), screen and (max-height: 450px) and (orientation : landscape) {
        @content;
      }
    } @else {
      @media screen and (max-width: $breakpoint-value) {
        @content;
      }
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

$vw-viewport: 1440;
@function get-vw($font) {
  $vw-context: $vw-viewport * 0.01 * 1px;
  @return $font / $vw-context * 1vw;
  @return $font;
}

$vw-viewport-md: 738;
@function get-vw-lg($font) {
  $vw-context-md: $vw-viewport-md * 0.01 * 1px;
  @return $font / $vw-context-md * 1vw;
  @return $font;
}

$vw-viewport-sm: 375;
@function get-vw-sm($font) {
  $vw-context-sm: $vw-viewport-sm * 0.01 * 1px;
  @return $font / $vw-context-sm * 1vw;
  @return $font;
}